import React, { useState, useMemo } from "react";
import { Grid, Typography, Avatar, CardHeader, Box, Divider, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import UserService from "../../services/user-service";
import { useQuery } from "react-query";
import { QUERIES } from "../../utils/constants";
import HomeCard from "../../ui-components/Cards/HomeCard";
import { History, Info } from "@mui/icons-material";
import { format } from "date-fns";
import Loading from "../../ui-components/Loading";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DataTable from "react-data-table-component";
import { startOfDay, endOfDay, sub } from "date-fns";
import FormattedDateTime from "../../utils/FormattedDateTime";

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
};

const UserHistoryCard = () => {
  const [startDate, setStartDate] = useState(startOfDay(sub(new Date(), { weeks: 1 })));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const { id } = useParams();

  // fetch user events
  const { data: userEvents, isFetching: getUserEventsFetching } = useQuery([QUERIES.Users, id, "events", startDate.toISOString(), endDate.toISOString()], () => UserService.getUserEvents(id, startDate.toISOString(), endDate.toISOString()));

  const columns = useMemo(
    () => [
      {
        name: "SEARCH",
        selector: (row) => row?.data,
        cells: (row) => {
          return <Box>{row.data}</Box>;
        },
        sortable: true,
      },
      {
        name: "DATE",
        selector: (row) => format(new Date(row.created), "MM/dd/yy hh:mm a"),
        cell: (row) => {
          return (
            <Box>
              <FormattedDateTime utcDateString={row.created} formatString={"MM/dd/yy"}/>
              <br />
              <FormattedDateTime utcDateString={row.created} formatString={"hh:mm a"}/>
            </Box>
          );
        },
        sortable: true,
      },
      {
        name: "DEVICE",
        selector: (row) => row?.deviceUniqueId,
        cell: (row) => {
          var deviceInfo = parseUserAgent(row?.userAgent);

          return (
            <Box>
              {row?.deviceUniqueId ? row?.deviceUniqueId?.slice(-12) : "Unknown"}
              <br />
              {deviceInfo?.device} {deviceInfo?.deviceBrowser}
              <br />({deviceInfo?.deviceOperatingSystem} {deviceInfo?.deviceOperatingSystemVersion})
            </Box>
          );
        },
      },
      {
        name: "IP LOCATION",
        selector: (row) => row?.ipAddress,
        cell: (row) => (
          <Box>
            {row?.ipCity}, {row?.ipRegion} {row?.ipCountry}
            <br />
            {row?.ipAddress}
          </Box>
        ),
      },
    ],
    []
  );

  const isLoading = getUserEventsFetching;

  function parseUserAgent(userAgent) {
    // Initialize default values
    let result = {
      device: "-",
      deviceOperatingSystem: "-",
      deviceBrowser: "-",
      deviceOperatingSystemVersion: "-",
    };

    // Regular expressions for various devices, OS, browsers, and versions
    const devicePatterns = {
      phone: /Mobile/,
      tablet: /Tablet/,
      desktop: /Windows NT|Macintosh/,
    };

    const osPatterns = {
      Windows: /Windows NT (\d+\.\d+)/,
      Mac: /Mac OS X (\d+[_\d]+)/,
      iPhone: /iPhone OS (\d+[_\d]+)/,
      Android: /Android (\d+[\.\d]+)/,
    };

    const browserPatterns = {
      Firefox: /Firefox\/([\d\.]+)/,
      Chrome: /Chrome\/([\d\.]+)/,
      Safari: /Safari\/([\d\.]+)/,
      Edge: /Edge\/([\d\.]+)/,
    };

    // Identifying the device
    for (const [device, pattern] of Object.entries(devicePatterns)) {
      if (pattern.test(userAgent)) {
        result.device = device;
        break;
      }
    }

    // Identifying the OS and its version
    for (const [os, pattern] of Object.entries(osPatterns)) {
      const match = userAgent.match(pattern);
      if (match) {
        result.deviceOperatingSystem = os;
        result.deviceOperatingSystemVersion = match[1].replace("_", ".");
        break;
      }
    }

    // Identifying the browser
    for (const [browser, pattern] of Object.entries(browserPatterns)) {
      const match = userAgent.match(pattern);
      if (match) {
        result.deviceBrowser = browser;
        break;
      }
    }

    return result;
  }
  return (
    <>
      {isLoading && <Loading />}
      <HomeCard>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#F5F5F5", height: 32, width: 32 }}>
              <History />
            </Avatar>
          }
          title={<Typography variant="h5">SEARCH HISTORY</Typography>}
        />
        <Divider />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <DatePicker label="Start Date" value={startDate} onChange={(value) => setStartDate(value)} />
              <DatePicker label="End Date" value={endDate} onChange={(value) => setEndDate(value)} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DataTable columns={columns} data={userEvents?.data?.result} theme={"dark"} customStyles={customStyles} pagination />
          </Grid>
        </Grid>
      </HomeCard>
    </>
  );
};

export default UserHistoryCard;
