import React, { useState } from "react";
import { Grid, Typography, TextField, Paper, Divider, InputAdornment, Box, IconButton } from "@mui/material";
import NoResultsCard from "../ui-components/NoResultsCard";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/constants";
import CatalyticConverterService from "../services/catalytic-converter-service";
import Loading from "../ui-components/Loading";
import { Close, Search } from "@mui/icons-material";
import ConverterCard from "../ui-components/ConverterCard";

const Converters = () => {
  const [searchQuery, setSearchQuery] = useState();

  const formik = useFormik({
    initialValues: {
      searchQuery: "",
    },
    onSubmit: (values) => {
      setSearchQuery(values.searchQuery);
    },
  });

  const { data: catalyticConverters, isFetching } = useQuery([QUERIES.Converters, searchQuery], () => CatalyticConverterService.getCatalyticConverters(searchQuery), {
    enabled: !!searchQuery,
  });

  return (
    <>
      {isFetching && <Loading />}

      <Paper elevation={0} sx={{ backgroundColor: "#2D2D2D", borderRadius: 0 }}>
        <Grid container sx={{ py: 2, px: 3 }} spacing={1}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
            <Typography variant="h5" mt={1}>
              Converters Search
            </Typography>
            <Divider sx={{ borderWidth: 1, mt: 1 }} />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                autoComplete="off"
                disabled={isFetching}
                autoFocus
                required
                fullWidth
                id="searchQuery"
                name="searchQuery"
                label="Search Converters"
                margin="normal"
                variant="filled"
                value={formik.values.searchQuery}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: formik.values.searchQuery && (
                    <InputAdornment position="start" onClick={() => formik.setFieldValue("searchQuery", "")}>
                      <IconButton>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" ml={1}>
              Showing {catalyticConverters?.data?.result?.length} search results for “{searchQuery}”
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={1} sx={{ p: 1, mt: 1 }}>
        {catalyticConverters?.data?.result?.length > 0 &&
          catalyticConverters?.data?.result?.slice(0, 100)?.map((converter) => (
            <Grid item key={`catalytic-converter-${converter.id}`} xs={12} sm={6} lg={4} xl={3}>
              <ConverterCard converter={converter} />
            </Grid>
          ))}
      </Grid>
      <Box sx={{ mt: 4 }}>{catalyticConverters?.data?.result && catalyticConverters?.data?.result?.length === 0 && <NoResultsCard />}</Box>
    </>
  );
};

export default Converters;
