import React, { useState, useEffect } from "react";
import { Typography, Alert, AlertTitle, useTheme, Collapse } from "@mui/material";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setDismissedId } from "../../slices/announcementSlice";
import { QUERIES } from "../../utils/constants";
import AnnouncementService from "../../services/announcement-service";
import { Notifications } from "@mui/icons-material";

const ApexAnnouncementAlert = () => {
  const { data: currentAlert } = useQuery([QUERIES.Announcements, "current"], () => AnnouncementService.getCurrentAnnouncement());
  const { lastDismissedId } = useSelector((state) => state.announcement);
  const dispatch = useDispatch();

  const theme = useTheme();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (lastDismissedId === null) {
      setOpen(true);
    }
  }, [lastDismissedId]);

  const handleClose = () => {
    setOpen(false);
    if (currentAlert?.data?.result?.id) {
      dispatch(setDismissedId(currentAlert.data.result.id));
    }
  };

  const isDismissed = currentAlert?.data?.result?.id === lastDismissedId;

  return (
    !isDismissed &&
    currentAlert?.data?.result?.description && (
      <Collapse in={open}>
        <Alert
          severity="warning"
          variant="standard"
          icon={< Notifications/>}
          sx={{
            position: "fixed",
            bottom: 60,
            right: 16,
            zIndex: theme.zIndex.fab,
          }}
          onClose={handleClose}>
          <AlertTitle>APEX ANNOUNCEMENT</AlertTitle>
          <Typography variant="body2">{currentAlert?.data?.result?.description}</Typography>
        </Alert>
      </Collapse>
    )
  );
};

export default ApexAnnouncementAlert;
