import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function MenuIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#60;Icon&#62;">
        <path id="Vector" d="M1 26H11V22.6667H1V26ZM1 6V9.33333H31V6H1ZM1 17.6667H21V14.3333H1V17.6667Z" fill="white" />
      </g>
    </SvgIcon>
  );
}

export default MenuIcon;
