import React, { useRef, useState } from "react";
import { Typography, Box, IconButton, Menu, MenuItem, Divider, Dialog, DialogTitle, DialogContent, Button, DialogActions, useTheme, useMediaQuery, CircularProgress } from "@mui/material";
import { CATALYTIC_CONVERTER_IMAGE_TYPES, QUERIES } from "../../utils/constants";
import { MoreVert } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import CatalyticConverterService from "../../services/catalytic-converter-service";
import Loading from "../Loading";
import { enqueueSnackbar } from "notistack";
import ImageService from "../../services/image-service";
import ImageLibraryDialog from "../Dialogs/ImageLibraryDialog";

const AdminUploadButton = ({ converter }) => {
  const fileInputRef = useRef(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [browseImageLibraryDialogOpen, setBrowseImageLibraryDialogOpen] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const [converterImageTypeId, setConverterImageTypeId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [removedBackgroundUrl, setRemovedBackgroundUrl] = useState(null);

  const queryClient = useQueryClient();

  const createBlobMutation = useMutation({
    mutationFn: (image) => ImageService.createBlob(image),
    onSuccess: (result) => {
      setConfirmDialogOpen(true);
      setImageUrl(result?.data?.result);
    },
  });

  const createBlobRemoveBackgroundMutation = useMutation({
    mutationFn: (image) => ImageService.createBlobRemoveBackground(image),
    onSuccess: (result) => {
      setRemovedBackgroundUrl(result?.data?.result);
    },
  });

  const createImageMutation = useMutation({
    mutationFn: (image) => ImageService.createImage(image),
  });

  const assignImageMutation = useMutation({
    mutationFn: (image) => CatalyticConverterService.assignImage(converter?.id, image),
  });

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleUploadButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTakeImage = (imageTypeId) => {
    setConverterImageTypeId(imageTypeId);

    fileInputRef.current.click();
  };

  const handleFileSelected = async (event) => {
    const file = event.target.files[0];
    handleMenuClose();

    if (!file) return;

    const formData = new FormData();
    formData.append("Image", file);

    setImageUrl(null);
    setRemovedBackgroundUrl(null);

    createBlobMutation.mutateAsync(formData);

    if (converterImageTypeId === CATALYTIC_CONVERTER_IMAGE_TYPES.CODE) return;
    createBlobRemoveBackgroundMutation.mutateAsync(formData);
  };

  const handleUploadImage = async () => {
    var imageDto = {
      name: converter?.name,
      category: converterImageTypeId,
      url: imageUrl,
      removedBackgroundUrl: removedBackgroundUrl,
    };

    var createResult = await createImageMutation.mutateAsync(imageDto);

    await assignImageMutation.mutateAsync({
      converterImageTypeId: converterImageTypeId,
      imageId: createResult?.data?.result?.id,
    });

    queryClient.invalidateQueries(QUERIES.Converters);
    queryClient.invalidateQueries(QUERIES.Images);

    enqueueSnackbar("Image Uploaded", { variant: "success" });

    handleConfirmDialogClose();
  };

  const handleBrowseImageLibraryOpen = () => {
    setBrowseImageLibraryDialogOpen(true);
    handleMenuClose();
  };

  const handleBrowseImageLibraryDialogClose = () => {
    setBrowseImageLibraryDialogOpen(false);
  };

  const isLoading = createBlobMutation.isLoading || assignImageMutation.isLoading || createImageMutation.isLoading || assignImageMutation.isLoading;

  return (
    <>
      {isLoading && <Loading />}
      <input type="file" accept="image/*" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelected} />
      <IconButton id="upload-button" onClick={handleUploadButtonClick} size="large" sx={{ position: "absolute", top: 8, right: 0, p: 2, borderRadius: 0 }}>
        <MoreVert fontSize="large" color="primary" />
      </IconButton>
      <Menu id="upload-menu" anchorOrigin={{ vertical: "top", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }} anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleTakeImage(CATALYTIC_CONVERTER_IMAGE_TYPES.MAIN)}>Upload Main Image</MenuItem>
        <MenuItem onClick={() => handleTakeImage(CATALYTIC_CONVERTER_IMAGE_TYPES.FRONT)}>Upload Front Image</MenuItem>
        <MenuItem onClick={() => handleTakeImage(CATALYTIC_CONVERTER_IMAGE_TYPES.BACK)}>Upload Back Image</MenuItem>
        <MenuItem onClick={() => handleTakeImage(CATALYTIC_CONVERTER_IMAGE_TYPES.CODE)}>Upload Code Image</MenuItem>
        <MenuItem onClick={() => handleTakeImage(CATALYTIC_CONVERTER_IMAGE_TYPES.SHIELD)}>Upload Shield Image</MenuItem>
        <MenuItem onClick={() => handleTakeImage(CATALYTIC_CONVERTER_IMAGE_TYPES.WITHOUT_SHIELD)}>Upload Without Shield Image</MenuItem>
        <MenuItem onClick={() => handleTakeImage(CATALYTIC_CONVERTER_IMAGE_TYPES.EXTRA)}>Upload Extra Image</MenuItem>
        <Divider />
        <MenuItem onClick={handleBrowseImageLibraryOpen}>Browse Image Library</MenuItem>
      </Menu>
      <Dialog
        open={confirmDialogOpen}
        aria-labelledby="upload-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        fullScreen={!isDesktop}
        PaperProps={{
          sx: {
            borderColor: "primary.outlinedBorder",
            borderWidth: 2,
            borderStyle: "solid",
          },
        }}>
        <DialogTitle id="upload-dialog-title">
          <Typography variant="h5" textAlign="center" mt={1}>
            Upload Image
          </Typography>
        </DialogTitle>
        <Divider sx={{ mx: 1, borderColor: "primary.outlinedBorder" }} />
        <DialogContent sx={{ p: 1, pt: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box component="img" src={imageUrl} sx={{ maxHeight: 240, maxWidth: "100%" }} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <>
              {createBlobRemoveBackgroundMutation.isLoading ? (
                <>
                  <Box mt={1}>
                    <CircularProgress size={32} color="inherit" />
                    <Typography>Removing Background...</Typography>
                  </Box>
                </>
              ) : (
                removedBackgroundUrl && <Box component="img" src={removedBackgroundUrl} sx={{ maxHeight: 240, maxWidth: "100%" }} />
              )}
            </>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}>
          <Button onClick={handleConfirmDialogClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleUploadImage} color="primary" variant="contained" disabled={createBlobRemoveBackgroundMutation.isLoading || isLoading}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      {browseImageLibraryDialogOpen && <ImageLibraryDialog converter={converter} onClose={handleBrowseImageLibraryDialogClose} />}
    </>
  );
};

export default AdminUploadButton;
