import { Grid, Button, Box } from "@mui/material";
import React, { useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import PageTitleBanner from "../ui-components/PageTitleBanner";
import ChevronButton from "../ui-components/Buttons/ChevronButton";
import Modal from "../ui-components/Modal";
import { useNavigate } from "react-router-dom";
import CatIcon from "../icons/CatIcon";
import ContactSpeedDial from "../ui-components/SpeedDials/ContactSpeedDial";
import EventService from "../services/event-service";

const Account = () => {
  const { currentUser, currentClaims, logOut } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSignOut = async () => {
    await logOut();
    setOpen(false);
  };

  return (
    <>
      <PageTitleBanner title="Account" />
      <Grid container sx={{ p: 2, mt: 2 }} spacing={1}>
        <Grid item xs={12}>
          <ChevronButton title="My Profile" to="/account/profile" />
        </Grid>
        <Grid item xs={12}>
          <ChevronButton title="Legal" to="/account/legal" />
        </Grid>
        <Grid item xs={12}>
          <ChevronButton title="Contact" to="/account/contact" />
        </Grid>
        <Grid item xs={12}>
          <ChevronButton title="Sign Out" onClick={handleModalOpen} />
        </Grid>
      </Grid>

      <ContactSpeedDial />

      <Modal
        open={open}
        handleModalClose={handleModalClose}
        title={"Sign out"}
        description={"Are you sure you want to sign out?"}
        icon={<CatIcon sx={{ width: 40, height: 40 }} />}
        buttons={[
          <Button onClick={handleSignOut} color="primary" fullWidth size="large" variant="contained">
            Sign out
          </Button>,
          <Button onClick={handleModalClose} color="primary" fullWidth size="large" variant="outlined">
            Cancel
          </Button>,
        ]}
      />
    </>
  );
};

export default Account;
