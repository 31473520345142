import { Grid, Box } from "@mui/material";
import React from "react";
import PageTitleBanner from "../../ui-components/PageTitleBanner";
import ChevronButton from "../../ui-components/Buttons/ChevronButton";
import { LEGAL_LINKS } from "../../utils/constants";
import ContactSpeedDial from "../../ui-components/SpeedDials/ContactSpeedDial";

const Legal = () => {
  return (
    <>
      
        <PageTitleBanner title="Legal" />
        <Grid container sx={{ p: 2, mt: 2 }} spacing={1}>
          <Grid item xs={12}>
            <ChevronButton title="Terms & Conditions" to={LEGAL_LINKS.TERMS_AND_CONDITIONS} />
          </Grid>
          <Grid item xs={12}>
            <ChevronButton title="Privacy Policy" to={LEGAL_LINKS.PRIVACY_POLICY} />
          </Grid>
        </Grid>
      
      <ContactSpeedDial />
    </>
  );
};

export default Legal;
