import React from "react";
import { Grid, Typography, Divider, Box, Button, TextField, LinearProgress } from "@mui/material";
import { format } from "date-fns";
import HomeCard from "./HomeCard";
import { FileUploader } from "react-drag-drop-files";
import { ChevronLeft, UploadFile } from "@mui/icons-material";
import { useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";
import { useMutation, useQueryClient, useQuery } from "react-query";
import PriceListService from "../../services/price-list-service";
import { enqueueSnackbar } from "notistack";
import { PRICE_LIST_TYPES, QUERIES } from "../../utils/constants";
import Loading from "../Loading";
import FormattedDateTime from "../../utils/FormattedDateTime";

const fileTypes = ["xlsx", "xls", "csv"];

const PriceListsCard = () => {
  const { data: priceLists, isFetching } = useQuery([QUERIES.PriceLists], () => PriceListService.getPricelists());

  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const uploadMutation = useMutation({
    mutationFn: PriceListService.uploadPriceList,
  });

  const priceListFormik = useFormik({
    initialValues: {
      priceList: null,
      name: null,
      platinumPrice: null,
      palladiumPrice: null,
      rhodiumPrice: null,
      step: 1,
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("PriceList", values.priceList);
      formData.append("TypeId", PRICE_LIST_TYPES.CONVERTER);
      formData.append("Name", values.name);

      if (values.platinumPrice) formData.append("PlatinumPrice", values.platinumPrice);
      if (values.palladiumPrice) formData.append("PalladiumPrice", values.palladiumPrice);
      if (values.rhodiumPrice) formData.append("RhodiumPrice", values.rhodiumPrice);

      try {
        var result = await uploadMutation.mutateAsync(formData);

        queryClient.invalidateQueries(QUERIES.PriceLists);

        enqueueSnackbar("Price List Uploaded", {
          variant: "success",
        });

        priceListFormik.resetForm();
      } catch (error) {
        enqueueSnackbar("Price List Upload Failed. Please make sure its an excel document.", {
          variant: "error",
        });
      }
    },
  });

  const handleChange = (currentFile) => {
    priceListFormik.setFieldValue("priceList", currentFile);

    var nameWithoutExtension = currentFile.name.split(".").slice(0, -1).join(".");
    priceListFormik.setFieldValue("name", nameWithoutExtension);
    priceListFormik.setFieldValue("step", 2);
  };

  const handleSizeError = (error) => {
    confirm({
      title: "File Size Error",
      description: error,
      confirmationText: "Ok",
      cancellationText: "",
    });
  };

  const step1 = () => {
    return (
      <FileUploader onSizeError={handleSizeError} maxSize={3} types={fileTypes} handleChange={handleChange}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px dashed #0F974F",
            borderRadius: "4px",
            backgroundColor: "rgba(15, 151, 79, 0.30)", // light green background
            py: 3, // padding
            px: 2,
            "&:hover": {
              backgroundColor: "rgba(76, 175, 80, 0.2)",
              cursor: "pointer",
            },
            gap: 1,
          }}>
          <UploadFile color="primary" sx={{ mr: 1 }} />
          <Typography variant="overline">
            <Button
              variant="text"
              color="primary"
              sx={{
                display: "inline",
                p: 0,
                mr: 1,
                textDecoration: "underline",
              }}>
              Click to Upload
            </Button>
            or Drag and Drop
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Excel/XLSX (max. 3MB)
          </Typography>
        </Box>
      </FileUploader>
    );
  };

  const step2 = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Assign a name to new price list
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField id="name" name="name" label="Price List Name" fullWidth variant="filled" required value={priceListFormik.values.name} onChange={priceListFormik.handleChange} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary" my={2}>
            Assign Markets
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField id="platinumPrice" name="platinumPrice" label="PT" fullWidth variant="filled" value={priceListFormik.values.platinumPrice} onChange={priceListFormik.handleChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField id="palladiumPrice" name="palladiumPrice" label="PD" fullWidth variant="filled" value={priceListFormik.values.palladiumPrice} onChange={priceListFormik.handleChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField id="rhodiumPrice" name="rhodiumPrice" label="RH" fullWidth variant="filled" value={priceListFormik.values.rhodiumPrice} onChange={priceListFormik.handleChange} />
        </Grid>
        <Grid item xs={12}>
          <Box mt={3} sx={{ gap: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button startIcon={<ChevronLeft />} variant="text" color="inherit" sx={{ color: "#757575" }} onClick={() => priceListFormik.setFieldValue("step", 1)}>
              Back
            </Button>
            <Box sx={{ flexGrow: 1, mx: 2 }}>
              <LinearProgress variant="determinate" value={50} sx={{ flexGrow: 1 }} />
            </Box>
            <Button variant="text" color="primary" onClick={priceListFormik.handleSubmit}>
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const isLoading = uploadMutation.isLoading || isFetching;

  return (
    <>
      <HomeCard>
        {isLoading && <Loading />}
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant="h5">PRICE LISTS</Typography>
            <Typography variant="overline" color="text.secondary">
              LAST UPLOADED <FormattedDateTime utcDateString={priceLists?.data?.result?.[0]?.created} formatString={"MM/dd/yy hh:mm a"} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          <Grid item xs={12}>
            {priceListFormik.values.step === 1 && step1()}
            {priceListFormik.values.step === 2 && step2()}
          </Grid>
        </Grid>
      </HomeCard>
    </>
  );
};

export default PriceListsCard;
