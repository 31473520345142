import api from "../utils/axios-client";

const getMe = async () => api.get("v1/users/me");

/**
 * Creates a new event
 *
 * @param {Object} data - The event
 * @param {string} data.typeId - the event type (1=search, 2=login, 3=logout)
 * @param {string} data.data - The data
 * @returns {Promise} A promise that resolves to the created user profile.
 */
const createEvent = async (data) => api.post("v1/events", data);

const EventService = {
  createEvent,
};

export default EventService;
