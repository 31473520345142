import React, { useState, useMemo } from "react";
import { Grid, Typography, TextField, InputAdornment, Box, IconButton, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { QUERIES } from "../utils/constants";
import { useQuery } from "react-query";
import Loading from "../ui-components/Loading";
import UserService from "../services/user-service";
import { format } from "date-fns";
import { useFormik } from "formik";
import { Search } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import ViewButton from "../ui-components/Buttons/ViewButton";
import { ArrowBackOutlined } from "@mui/icons-material";
import NoResultsCard from "../ui-components/NoResultsCard";
import FormattedDateTime from "../utils/FormattedDateTime";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
};

const Users = () => {
  const navigate = useNavigate();
  const [prevSearch, setPrevSearch] = useState("");

  const { data: usersData, isFetching: getUsersFetching } = useQuery([QUERIES.Users, "admin", prevSearch], () => UserService.getUsers(prevSearch));

  const isLoading = getUsersFetching;

  const searchFormik = useFormik({
    initialValues: {
      searchQuery: "",
    },
    onSubmit: async (values) => {
      setPrevSearch(values.searchQuery);
    },
    enableReinitialize: true,
  });

  const columns = useMemo(
    () => [
      {
        name: "NAME",
        selector: (row) => `${row.firstName} ${row.lastName}`,
        sortable: true,
      },
      {
        name: "BUSINESS NAME",
        selector: (row) => row.company,
        sortable: true,
      },
      {
        name: "EMAIL",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "PHONE NUMBER",
        selector: (row) => row.phoneNumber,
        cell: (row) => {
          const rawNumber = row.phoneNumber;
          const formattedNumber = `+${rawNumber.slice(1, 2)} (${rawNumber.slice(2, 5)}) ${rawNumber.slice(5, 8)}-${rawNumber.slice(8)}`;
          return formattedNumber;
        },
        sortable: true,
      },
      {
        name: "CREATED",
        selector: (row) => format(new Date(row.created), "MM/dd/yy hh:mm a"),
        cell: (row) => {
          return (
            <Box>
              <FormattedDateTime utcDateString={row.created} formatString={"MM/dd/yy"} />
              <br />
              <FormattedDateTime utcDateString={row.created} formatString={"hh:mm a"} />
            </Box>
          );
        },
        sortable: true,
      },
      {
        cell: (row) => <ViewButton to={`/admin/users/${row.id}`} title={"VIEW"} />,
        ignoreRowClick: true,
      },
    ],
    []
  );

  return (
    <>
      {isLoading && <Loading />}
      <Grid container sx={{ p: 2, backgroundColor: "#2D2D2D" }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackOutlined />
            </IconButton>
            <Typography variant="h5">APEX Users</Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={searchFormik.handleSubmit}>
            <Box sx={{ m: 1 }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="searchQuery"
                name="searchQuery"
                label="Search Users"
                value={searchFormik.values.searchQuery}
                onChange={searchFormik.handleChange}
              />
              {prevSearch && (
                <Typography variant="body2" sx={{ my: 1 }}>
                  Showing Search Results for "{prevSearch}"
                </Typography>
              )}
            </Box>
          </form>
        </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: "#2D2D2D" }}>
        <DataTable columns={columns} data={usersData?.data?.result} theme={"dark"} customStyles={customStyles} navigate={navigate} />
      </Grid>
    </>
  );
};

export default Users;
