import React from "react";
import { Typography, Card, CardContent, Grid, IconButton, Divider, Paper } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { ChevronRight, TrendingDown, TrendingUp } from "@mui/icons-material";
import { QUERIES } from "../../utils/constants";
import { useQuery } from "react-query";
import MetalPriceService from "../../services/metal-price-service";
import MetalsCard from "./MetalsCard";
import Loading from "../Loading";
import HomeCard from "./HomeCard";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const MarketsCard = ({ hideButton = false, hasBorder = false }) => {
  // get current metal prices
  const { data: metalPrices, isFetching: getMetalPricesFetching } = useQuery([QUERIES.MetalPrices, "current"], () => MetalPriceService.getMetalPrices(), {
    staleTime: 1000 * 60 * 30, // 30 minutes in milliseconds
  });

  const isLoading = getMetalPricesFetching;

  const ptPrice = metalPrices?.data?.result?.find((metal) => metal.symbol === "PT");
  const pdPrice = metalPrices?.data?.result?.find((metal) => metal.symbol === "PD");
  const rhPrice = metalPrices?.data?.result?.find((metal) => metal.symbol === "RH");

  return (
    <>
      {isLoading && <Loading />}
      <Paper elevation={0} sx={hasBorder ? { borderRadius: 1, border: 1, borderColor: "primary.main", backgroundColor: "#2D2D2D", pb: 2 } : { border: 0, backgroundColor: "#2D2D2D", pb: 2 }}>
        <Grid container sx={{ py: 2, px: 3 }} spacing={1}>
          <Grid item xs={!hideButton ? 10 : 12}>
            <Typography variant="h5">Markets</Typography>
            <Typography variant="overline" color="text.secondary">
              {format(new Date(), "MMMM do, h:mm a")}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Grid>
          {!hideButton && (
            <Grid item xs={2} container sx={{ justifyContent: "center", alignItems: "center", pb: 2 }}>
              <IconButton component={Link} to="/markets">
                <ChevronRight sx={{ fontSize: 40 }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{ p: 2 }}>
          <Grid item xs={4}>
            {ptPrice && <MetalsCard metal={ptPrice} />}
          </Grid>
          <Grid item xs={4}>
            {pdPrice && <MetalsCard metal={pdPrice} />}
          </Grid>
          <Grid item xs={4}>
            {rhPrice && <MetalsCard metal={rhPrice} />}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default MarketsCard;
