import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastDismissedId: null,
};
const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    setDismissedId: (state, action) => {
      state.lastDismissedId = action.payload;
    },
  },
});

export const { setDismissedId } = announcementSlice.actions;

export default announcementSlice.reducer;
