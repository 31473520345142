import React, { useState } from "react";
import { Grid, Typography, TextField, Avatar, CardHeader, Divider, IconButton, CardActions, Button } from "@mui/material";
import UserService from "../../services/user-service";
import { QueryClient, useMutation } from "react-query";
import HomeCard from "./HomeCard";
import { Edit, Person } from "@mui/icons-material";
import { format } from "date-fns";
import { useFormik } from "formik";
import { QUERIES } from "../../utils/constants";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Loading from "../../ui-components/Loading";
import FormattedDateTime from "../../utils/FormattedDateTime";

const UserGeneralCard = () => {
  const [canEdit, setCanEdit] = useState(false);
  const { id } = useParams();
  const queryClient = new QueryClient();

  // fetch user data
  const { data: userData, isFetching: getUserFetching } = useQuery([QUERIES.Users, id], () => UserService.getUser(id));

  // update user mutation
  const updateUserMutation = useMutation({
    mutationFn: (values) => UserService.updateUser(id, values),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERIES.Users, id]);
    },
  });

  // store user details
  const userDetails = userData?.data?.result;

  // formik for info form
  const infoFormik = useFormik({
    initialValues: {
      company: userDetails?.company ?? "",
      firstName: userDetails?.firstName ?? "",
      lastName: userDetails?.lastName ?? "",
      phoneNumber: userDetails?.phoneNumber ?? "",
      email: userDetails?.email ?? "",
    },
    onSubmit: async (values) => {
      setCanEdit(false);

      // update user
      await updateUserMutation.mutateAsync(values);
      openSnackSuccess();
    },
    enableReinitialize: true,
  });

  // snack success message
  const openSnackSuccess = () => {
    enqueueSnackbar("User information updated", { variant: "success" });
  };

  // loading
  const isLoading = getUserFetching || updateUserMutation.isLoading;

  return (
    <>
      {isLoading && <Loading />}
      <HomeCard>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#F5F5F5", height: 32, width: 32 }}>
              <Person />
            </Avatar>
          }
          action={
            <IconButton onClick={() => setCanEdit(!canEdit)}>
              <Edit />
            </IconButton>
          }
          title={<Typography variant="h5">GENERAL INFORMATION</Typography>}
        />
        <Divider />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <TextField
              disabled={!canEdit}
              fullWidth
              variant="filled"
              label="Business Name"
              InputLabelProps={{
                shrink: true,
              }}
              value={infoFormik?.values?.company ?? ""}
              onChange={(e) => infoFormik.setFieldValue("company", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!canEdit}
              fullWidth
              variant="filled"
              label="First Name"
              InputLabelProps={{
                shrink: true,
              }}
              value={infoFormik?.values?.firstName ?? ""}
              onChange={(e) => infoFormik.setFieldValue("firstName", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!canEdit}
              fullWidth
              variant="filled"
              label="Last Name"
              InputLabelProps={{
                shrink: true,
              }}
              value={infoFormik?.values?.lastName ?? ""}
              onChange={(e) => infoFormik.setFieldValue("lastName", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              fullWidth
              variant="filled"
              label="Phone Number"
              InputLabelProps={{
                shrink: true,
              }}
              value={infoFormik?.values?.phoneNumber ?? ""}
              onChange={(e) => infoFormik.setFieldValue("phoneNumber", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              fullWidth
              variant="filled"
              label="Email"
              InputLabelProps={{
                shrink: true,
              }}
              value={infoFormik?.values?.email ?? ""}
              onChange={(e) => infoFormik.setFieldValue("email", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} mt={2} mb={3}>
            {userDetails?.created && (
              <Typography>
                Account Created <FormattedDateTime utcDateString={userDetails?.created} formatString={"MM/dd/yy hh:mm a"} />
              </Typography>
            )}
          </Grid>
        </Grid>
        {canEdit && (
          <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setCanEdit(false);
                infoFormik.resetForm();
              }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={infoFormik.handleSubmit}>
              Save
            </Button>
          </CardActions>
        )}
      </HomeCard>
    </>
  );
};

export default UserGeneralCard;
