import api from "../utils/axios-client";

const getMe = async () => api.get("v1/users/me");

/**
 * Creates a user profile.
 * @param {Object} data - The user profile data.
 * @param {string} data.email - The email of the user.
 * @param {string} data.firstName - The first name of the user.
 * @param {string} data.lastName - The last name of the user.
 * @param {string} [data.company] - The company of the user (optional).
 * @returns {Promise} A promise that resolves to the created user profile.
 */
const createUser = async (data) => api.post("v1/users/me/profile", data);

/**
 * Gets all users.
 * @param {string} [search] - The search query.
 * @returns {Promise} A promise that resolves to all users.
 */
const getUsers = async (search) => api.get("v1/users", { params: { search } });

/**
 * Gets a user by id.
 * @param {string} id - The user id.
 * @returns {Promise} A promise that resolves to the user.
 */
const getUser = async (id) => api.get(`v1/users/${id}`);

/**
 * Gets a user's events by id.
 * @param {string} id - The user id.
 * @param {string} [startDate] - The start date.
 * @param {string} [endDate] - The end date.
 * @returns {Promise} A promise that resolves to the user.
 */
const getUserEvents = async (id, startDate = null, endDate = null) => api.get(`v1/users/${id}/events`, { params: { startDate, endDate } });

/**
 * Updates a user profile.
 * @param {string} id - The user id.
 * @param {Object} data - The user profile data.
 * @param {string} data.email - The email of the user.
 * @param {string} data.firstName - The first name of the user.
 * @param {string} data.lastName - The last name of the user.
 * @param {string} [data.company] - The company of the user (optional).
 * @returns {Promise} A promise that resolves to the created user profile.
 */
const updateUser = async (id, data) => api.put(`v1/users/${id}/`, data);

/**
 * Updates a user's permissions.
 * @param {string} id - The user id.
 * @param {Object} data - The permissions data.
 * @returns {Promise} A promise that resolves to the updated permissions.
 */
const updateUserPermissions = async (id, data) => api.put(`v1/users/${id}/permissions`, data);

/**
 * Updates a user's override converter price list.
 * @param {string} id - The user id.
 * @param {Object} data - The override converter price list id.
 * @returns {Promise} A promise that resolves to the updated price list.
 */
const updateOverrideConverterPriceList = async (id, data) => api.post(`v1/users/${id}/override-converter-price-list`, data);

/**
 * Disables a user.
 * @param {string} id - The user id.
 * @returns {Promise} A promise that resolves when the user is disabled.
 */
const disableUser = async (id) => api.post(`v1/users/${id}/disable`);

/**
 * Enables a user.
 * @param {string} id - The user id.
 * @returns {Promise} A promise that resolves when the user is enabled.
 */
const enableUser = async (id) => api.post(`v1/users/${id}/enable`);

const UserService = {
  getMe,
  createUser,
  getUsers,
  getUser,
  getUserEvents,
  updateUser,
  updateUserPermissions,
  updateOverrideConverterPriceList,
  disableUser,
  enableUser,
};

export default UserService;
