import React, { useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import NonFerrousScrap from "../../icons/NonFerrousScrap";
import FerrousScrap from "../../icons/FerrousScrap";
import EScrap from "../../icons/EScrap";
import PropertyListIcon from "../../icons/PropertyListIcon";
import { Backdrop, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const actions = [
  { icon: <EScrap />, name: "E-Scrap", path: "/e-scrap" },
  { icon: <NonFerrousScrap />, name: "Non-Ferrous Scrap", path: "/non-ferrous" },
  { icon: <FerrousScrap />, name: "Ferrous Scrap", path: "/ferrous" },
];

function IconWithText({ icon, text }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ mr: 1, fontSize: "18px" }}>{text}</Typography>
      {icon}
    </Box>
  );
}

const ScrapListSpeedDial = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          transform: "translateZ(0px)",
          flexGrow: 1,
          position: "fixed",
          bottom: 24,
          right: 16,
          zIndex: 1,
          "#SpeedDial-actions": {
            alignSelf: "flex-end",
          },
        }}>
        <SpeedDial
          ariaLabel="SpeedDial"
          icon={<IconWithText icon={<PropertyListIcon />} text="Scrap List" />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          sx={{
            ".MuiSpeedDial-fab": {
              width: "100%",
              px: 2,
              borderRadius: "100px",
              ":hover": {
                backgroundColor: "primary.dark",
              },
            },
          }}>
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => navigate(action.path)}
              sx={{
                ".MuiSpeedDialAction-staticTooltipLabel": {
                  backgroundColor: "#616161E5",
                  color: "white",
                  width: "max-content",
                },
                ".MuiSpeedDialAction-fab": {
                  backgroundColor: "white",
                  borderWidth: "2px",
                  borderColor: "primary.main",
                  borderStyle: "solid",
                  ":hover": {
                    backgroundColor: "#cdcdcd",
                  },
                },
              }}
            />
          ))}
        </SpeedDial>
      </Box>
      <Backdrop open={open} onClick={handleClose} />
    </>
  );
};

export default ScrapListSpeedDial;
