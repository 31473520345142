import React from "react";
import { Box, IconButton, Divider } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import { IMAGES } from "../utils/constants";

const LoginNav = ({ step, setStep }) => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}>
        <IconButton onClick={() => setStep(step)} sx={{ position: "absolute", left: 0 }}>
          <ArrowBackOutlined />
        </IconButton>
        <Box component="img" src={IMAGES.LOGO_LIGHT} sx={{ height: 24, mt: 1 }} />
      </Box>
      <Divider color="primary" sx={{ mt: 2 }} />
    </>
  );
};

export default LoginNav;
