import React from "react";
import { Button } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";

const ViewButton = ({ title, to, onClick }) => {
  return (
    <>
      <Button variant="contained" color="primary" endIcon={<ChevronRight />} component={Link} to={to} onClick={onClick}>
        {title}
      </Button>
    </>
  );
};

export default ViewButton;
