import { configureStore } from "@reduxjs/toolkit";
import marginReducer from "../slices/marginSlice";
import announcementReducer from "../slices/announcementSlice";
import deviceReducer from "../slices/deviceSlice";

// Load state from local storage
function loadState() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

// Save state to local storage
function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
}

const persistedState = loadState();

const store = configureStore({
  reducer: {
    margin: marginReducer,
    announcement: announcementReducer,
    device: deviceReducer,
  },
  preloadedState: persistedState,
  announcement: announcementReducer,
});

store.subscribe(() => {
  saveState({
    margin: store.getState().margin,
    announcement: store.getState().announcement,
  });
});

export default store;
