import { Box, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import MarketsCard from "../ui-components/Cards/MarketsCard";
import DataTable from "react-data-table-component";
import { NumericFormat } from "react-number-format";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
};

const marketData = [
  // TODO: We need to get the scrap data from the API.
  // {
  //   commodity: "#1 Copper",
  //   category: "Copper",
  //   bidPrice: 4.0,
  //   askPrice: 4.5,
  //   change: 0.5,
  // },
];

const Markets = () => {
  const columns = useMemo(
    () => [
      {
        name: "Commodity",
        selector: (row) => row.commodity,
        sortable: true,
      },
      {
        name: "Category",
        selector: (row) => row.category,
        sortable: true,
      },
      {
        name: "Bid Price",
        cell: (row) => {
          return <NumericFormat value={row.bidPrice} displayType={"text"} thousandSeparator={true} prefix={"$"} fixedDecimalScale decimalScale={2} />;
        },
        sortable: true,
      },
      {
        name: "Ask Price",
        cell: (row) => {
          return <NumericFormat value={row.askPrice} displayType={"text"} thousandSeparator={true} prefix={"$"} fixedDecimalScale decimalScale={2} />;
        },
        sortable: true,
      },
      {
        name: "Change",
        cell: (row) => {
          return <NumericFormat value={row.change} displayType={"text"} thousandSeparator={true} suffix={"%"} fixedDecimalScale decimalScale={2} />;
        },
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ backgroundColor: "#2D2D2D",}}>
            <MarketsCard hideButton={true} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            noDataComponent={
              <>
                <Box sx={{ py: 3 }}>
                  <Typography variant="body1">More Market Data Coming Soon..</Typography>
                </Box>
              </>
            }
            columns={columns}
            data={marketData}
            theme={"TableTheme"}
            customStyles={customStyles}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Markets;
