import React, { useState } from "react";
import PageTitleBanner from "../../ui-components/PageTitleBanner";
import { Grid, TextField, Button, Box, Alert, Typography, Divider } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Loading from "../../ui-components/Loading";
import Modal from "../../ui-components/Modal";
import CatIcon from "../../icons/CatIcon";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ContactService from "../../services/contact-service";
import UserService from "../../services/user-service";
import { QUERIES } from "../../utils/constants";

const contactValidationSchema = yup.object({
  message: yup.string().required("A message is required"),
});

const Contact = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // get user profile
  const { data: userProfile, isFetching: getMeFetching } = useQuery([QUERIES.Users, "me"], () => UserService.getMe());

  const sendContactMessage = async (values) => {
    const message = `<p>User <b>${userProfile?.data?.result?.email}</b>, is sending a contact message:</p>
      <p>${values.message}</p>
    `;

    const response = await ContactService.sendMessage({ message: message });
  };

  const mutation = useMutation(sendContactMessage);
  // Contact form
  const contactFormik = useFormik({
    initialValues: {
      message: "",
    },
    contactValidationSchema,
    onSubmit: async (values) => {
      await mutation.mutateAsync(values);
      handleModalOpen();
    },
    enableReinitialize: true,
  });

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    navigate("/");
  };

  const isLoading = contactFormik.isSubmitting || mutation.isLoading || getMeFetching;

  return (
    <>
      {isLoading && <Loading />}

      <PageTitleBanner title="Contact" />
      <Grid container sx={{ p: 2, mt: 2 }}>
        <Grid item sm={1} md={3} lg={4}></Grid>
        <Grid item xs={12} sm={10} md={6} lg={4}>
          {isLoading && <Loading />}
          <Box
            component={"form"}
            onSubmit={contactFormik.handleSubmit}
            sx={{
              backgroundColor: "#2D2D2D",
              borderWidth: "2px",
              borderColor: "primary.main",
              borderStyle: "solid",
              borderRadius: "4px",
              py: 3,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>
            {contactFormik.errors.submit && (
              <Alert severity="error" variant="outlined">
                <strong>Error: </strong>
                {contactFormik.errors.submit}
              </Alert>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <CatIcon sx={{ width: 40, height: 40 }} />
              <Typography variant="h5" component="div" textAlign="center">
                Send Us a Message
              </Typography>
              <CatIcon sx={{ width: 40, height: 40 }} />
            </Box>
            <Divider sx={{ backgroundColor: "primary.main" }} />
            <Typography>Questions? Comments? Feel free to send us a message and a member of our team will get back with you ASAP.</Typography>
            <TextField
              fullWidth
              variant="outlined"
              id="message"
              name="message"
              label="Enter your message here"
              value={contactFormik.values.message}
              onChange={contactFormik.handleChange}
              error={contactFormik.touched.message && Boolean(contactFormik.errors.message)}
              helperText={contactFormik.touched.message && contactFormik.errors.message}
              multiline
              rows={4}
            />
            <Button size="large" color="primary" variant="contained" fullWidth type="submit" disabled={isLoading || !contactFormik.dirty}>
              Send Message
            </Button>

            <Modal
              open={open}
              handleModalClose={handleModalClose}
              title={"Message Sent"}
              description={"Your message has been sent. Please allow us 24 hours to respond back to you. Thanks!"}
              icon={<CatIcon sx={{ width: 40, height: 40 }} />}
              buttons={[
                <Button size="large" onClick={handleModalClose} color="primary" fullWidth variant="contained">
                  Return Home
                </Button>,
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
