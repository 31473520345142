import { Typography, Grid, Divider, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBackOutlined } from "@mui/icons-material";
import React from "react";

const PageTitleBanner = ({ title }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box p={2} sx={{ backgroundColor: "#2D2D2D" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1, gap: 1 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="h5">{title}</Typography>
        </Box>

        <Divider />
      </Box>
    </>
  );
};

export default PageTitleBanner;
