import api from "../utils/axios-client";

/**
 * Gets images by search parameter.
 * 
 * @param {string} search 
 * @returns 
 */
const getImages = async (search) => api.get("v1/images", { params: { search } });

/**
 * Uploads an image file to get back the url.
 * 
 * @param {File} image - The catalytic converter image.
 * @param {string} image.image - The catalytic converter image name
 */
const createBlob = async (image) => {
  return api.post(`v1/images/blob`, image, { headers: { "Content-Type": "multipart/form-data" } });
};

/**
 * Uploads an image file to get back the url.
 * 
 * @param {File} image - The catalytic converter image.
 * @param {string} image.image - The catalytic converter image name
 */
const createBlobRemoveBackground = async (image, fitToContent = false) => {
  return api.post(`v1/images/blob/remove-background`, image, { params: { fitToContent }, headers: { "Content-Type": "multipart/form-data" } });
};


/**
 * Creates an image in the database, returns the image object
 * 
 * @param {Object} image - The catalytic converter image.
 * @param {string} image.name - The catalytic converter image name
 * @param {string} image.category - The catalytic converter image category
 * @param {string} image.description - The catalytic converter image description
 * @param {string} image.url - The catalytic converter image url
 * @param {string} image.removedBackgroundUrl - The catalytic converter image removed background url
 */
const createImage = async (image) => api.post(`v1/images`, image);

const ImageService = {
  createBlob,
  createBlobRemoveBackground,
  createImage,
  getImages
};

export default ImageService;
