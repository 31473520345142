import React from "react";
import { Button } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { Link } from "react-router-dom";

const ChevronButton = ({ title, to, onClick }) => {
  return (
    <>
      <Button
        sx={{
          mb: 3,
          justifyContent: "space-between",
          backgroundColor: "#2D2D2D",
          border: 1,
          borderColor: "primary.outlinedBorder",
        }}
        variant="contained"
        size="large"
        fullWidth
        endIcon={<ArrowForwardIos />}
        component={Link}
        onClick={onClick}
        to={to}>
        {title}
      </Button>
    </>
  );
};

export default ChevronButton;
