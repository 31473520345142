import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PrivateOutlet from "./PrivateOutlet";
import Converters from "./pages/Converters";
import NotFound from "./pages/NotFound";
import PriceLists from "./pages/PriceLists";
import Account from "./pages/Account";
import Legal from "./pages/Account/Legal";
import Profile from "./pages/Account/Profile";
import Contact from "./pages/Account/Contact";
import Margin from "./pages/Margin";
import Users from "./pages/Users";
import UserDetails from "./pages/UserDetails";
import Markets from "./pages/Markets";
import Ferrous from "./pages/scrap/Ferrous";
import NonFerrous from "./pages/scrap/NonFerrous";
import EScrap from "./pages/scrap/EScrap";
import Cores from "./pages/Cores";
import Subscriptions from "./pages/Subscriptions";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<PrivateOutlet />}>
        <Route path="/" element={<Home />} />
        <Route path="/account" element={<Account />} />
        <Route path="/account/legal" element={<Legal />} />
        <Route path="/account/profile" element={<Profile />} />
        <Route path="/account/contact" element={<Contact />} />
        <Route path="/converters" element={<Converters />} />
        <Route path="/margin" element={<Margin />} />
        <Route path="/admin/price-lists" element={<PriceLists />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/users/:id" element={<UserDetails />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/ferrous" element={<Ferrous />} />
        <Route path="/non-ferrous" element={<NonFerrous />} />
        <Route path="/e-scrap" element={<EScrap />} />
        <Route path="/cores" element={<Cores />} />
        <Route path="/subscriptions" element={<Subscriptions />} />

        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}
