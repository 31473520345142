import React, { useState } from "react";
import { Alert, Box, Button, Container, Grid, Link, TextField, Typography, useMediaQuery } from "@mui/material";
import { signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import UserService from "../services/user-service";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";
import Loading from "../ui-components/Loading";
import { EVENT_TYPES, IMAGES, LEGAL_LINKS } from "../utils/constants";
import { useTheme } from "@emotion/react";
import { PatternFormat } from "react-number-format";
import { Info } from "@mui/icons-material";
import { MuiOtpInput } from "mui-one-time-password-input";
import Modal from "../ui-components/Modal";
import LoginNav from "../ui-components/LoginNav";
import EventService from "../services/event-service";

const validationSchema = yup.object({
  phoneNumber: yup.string().length(10, "Phone number must be 10 digits").required("Phone number is required"),
});

const codeValidationSchema = yup.object({
  code: yup.string().required("Code is required"),
});

const profileValidationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  company: yup.string(),
  email: yup.string().email("Please enter a valid email address").required("Email is required"),
});

const steps = {
  phone: "phone",
  terms: "terms",
  code: "code",
  profile: "profile",
};

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(steps.phone);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleModalOpen = async () => {
    const appVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
      size: "invisible",
    });
    try {
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);

      setConfirmationResult(confirmationResult);
      // if successful open modal
      setOpen(true);
    } catch (error) {
      console.error(error?.code);
      codeFormik.setErrors({
        code: "Too many attempts.",
      });
    }
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  // Phone number form
  const phoneFormik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const appVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
        size: "invisible",
      });

      try {
        const formattedPhoneNumber = `+1${values.phoneNumber}`;
        // save phone number for resend code
        setPhoneNumber(formattedPhoneNumber);
        const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier);

        setConfirmationResult(confirmationResult);
        setStep(steps.terms);
      } catch (error) {
        console.error(error?.code);
        phoneFormik.setErrors({
          phoneNumber: "Invalid phone number or too many attempts.",
        });
      }
    },
  });

  // Verification code form
  const codeFormik = useFormik({
    initialValues: {
      code: "",
    },
    codeValidationSchema,
    onSubmit: async (values) => {
      try {
        const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, values.code);
        var signInResult = await signInWithCredential(auth, credential);

        // Get user profile
        const userProfile = await UserService.getMe();

        try {
          EventService.createEvent({ typeId: EVENT_TYPES.LOGIN });
        } catch (error) {
          console.error(error);
        }

        if (userProfile?.data?.result?.email === null) {
          // if email is null set step to profile form
          setStep(steps.profile);
        } else {
          // else navigate to home page
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        codeFormik.setErrors({ code: "Invalid code" });
      }
    },
  });

  // Handle OTP change
  const handleOtpChange = (otp) => {
    codeFormik.setFieldValue("code", otp);
  };

  // Profile form
  const profileFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      email: "",
    },
    profileValidationSchema,
    onSubmit: async (values) => {
      try {
        const userId = auth.currentUser.uid;
        const createUserResult = await UserService.createUser({
          ...values,
          externalId: userId,
        });

        // if successful navigate to home page
        navigate("/");
      } catch (error) {
        console.error(error);
        profileFormik.setErrors({
          submit: "An error occurred when updating the profile.",
        });
      } finally {
        profileFormik.setSubmitting(false);
      }
    },
  });

  const isLoading = phoneFormik.isSubmitting || codeFormik.isSubmitting || profileFormik.isSubmitting;

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        background: isDesktop ? `url(${IMAGES.BACKGROUND_DESKTOP})` : `url(${IMAGES.BACKGROUND_MOBILE})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
      }}>
      <Grid container>
        <Grid item sm={1} md={3} lg={4}></Grid>
        <Grid item xs={12} sm={10} md={6} lg={4}>
          {isLoading && <Loading />}
          <Box
            sx={{
              p: 2,
              pt: 5,
            }}>
            {step === steps.phone && (
              <>
                <Typography mb={2} variant="h6">
                  Welcome to
                </Typography>
                <Box sx={{ display: "flex", mb: 7 }}>
                  <Box
                    component={"img"}
                    src={IMAGES.LOGO_LIGHT}
                    sx={{
                      maxWidth: "100%",
                    }}
                  />
                </Box>
                <Typography mb={4} variant="body1" color={"text.secondary"}>
                  Please enter your phone number to continue
                </Typography>
                <form onSubmit={phoneFormik.handleSubmit}>
                  <PatternFormat
                    name="phoneNumber"
                    id="phoneNumber"
                    value={phoneFormik.values.phoneNumber}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      phoneFormik.setFieldValue("phoneNumber", value);
                    }}
                    format="+1 (###) ### ####"
                    allowEmptyFormatting
                    mask="_"
                    customInput={TextField}
                    label="Phone Number"
                    variant="filled"
                    margin="normal"
                    type="tel"
                    error={phoneFormik.touched.phoneNumber && Boolean(phoneFormik.errors.phoneNumber)}
                    helperText={phoneFormik.touched.phoneNumber && phoneFormik.errors.phoneNumber}
                    fullWidth
                  />
                  <Button sx={{ mt: 5 }} id="sign-in-button" size="large" color="primary" variant="contained" fullWidth type="submit" disabled={phoneFormik.isSubmitting}>
                    Next
                  </Button>
                </form>
              </>
            )}
            {step === steps.terms && (
              <>
                <LoginNav step={"phone"} setStep={setStep} />

                <Typography mt={4} variant="h6">
                  Terms of Use
                </Typography>
                <Typography mt={3} variant="body1" color={"text.secondary"}>
                  To continue using our app and to improve your user experience, we've made some updates to our {""}
                  <Link href={LEGAL_LINKS.TERMS_AND_CONDITIONS} target="_blank" color={"text.secondary"}>
                    <strong>Terms of Use.</strong>
                  </Link>
                </Typography>
                <Typography mt={2} variant="body1" color={"text.secondary"}>
                  Please take a moment to review the changes. By continuing to use our app, you are agreeing to the updated terms, including message and data rates.
                </Typography>
                <Button sx={{ mt: 5 }} id="sign-in-button" size="large" color="primary" variant="contained" fullWidth onClick={() => setStep(steps.code)}>
                  I AGREE
                </Button>
              </>
            )}
            {step === steps.code && (
              <>
                <LoginNav step={"terms"} setStep={setStep} />
                <Typography mt={4} variant="h6">
                  Code Verification
                </Typography>
                <Typography mt={3} variant="body1" color={"text.secondary"}>
                  We've sent a secure code to your phone via text message. Please check your messages for the code.
                </Typography>
                <form onSubmit={codeFormik.handleSubmit}>
                  {codeFormik.errors.code && (
                    <>
                      <Alert severity="error" variant="outlined" sx={{ mt: 2 }}>
                        <strong>Error: </strong>
                        {codeFormik.errors.code}
                      </Alert>
                    </>
                  )}

                  <Box sx={{ mt: 2 }}>
                    <MuiOtpInput id="code" name="code" label="Verification Code" value={codeFormik.values.code} onChange={handleOtpChange} length={6} gap={1} display={"flex"} />
                  </Box>

                  <Box sx={{ mt: 1 }}>
                    <Button id="sign-in-button" onClick={handleModalOpen}>
                      Resend Code
                    </Button>
                  </Box>

                  <Button sx={{ mt: 4 }} id="sign-in-button" size="large" color="primary" variant="contained" fullWidth type="submit" disabled={codeFormik.isSubmitting}>
                    VERIFY MY CODE
                  </Button>
                </form>

                <Modal
                  open={open}
                  handleModalClose={handleModalClose}
                  title={"Code Resent"}
                  description={"We have sent you a new secure code. Please check your message and enter your new code."}
                  icon={<Info color="primary" sx={{ fontSize: 40 }} />}
                  buttons={[
                    <Button onClick={handleModalClose} color="primary" fullWidth variant="contained">
                      OKAY
                    </Button>,
                  ]}
                />
              </>
            )}
            {step === steps.profile && (
              <>
                <LoginNav step={"code"} setStep={setStep} />
                <Typography mt={4} variant="h6">
                  Complete Your Profile
                </Typography>
                <Typography mt={2} mb={4} variant="body1" color={"text.secondary"}>
                  To optimize your experience, we need to get some information about you.
                </Typography>
                <form onSubmit={profileFormik.handleSubmit}>
                  {profileFormik.errors.submit && (
                    <Alert severity="error" variant="outlined" sx={{ mt: 2 }}>
                      <strong>Error: </strong>
                      {profileFormik.errors.submit}
                    </Alert>
                  )}
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={profileFormik.values.firstName}
                    onChange={profileFormik.handleChange}
                    error={profileFormik.touched.firstName && Boolean(profileFormik.errors.firstName)}
                    helperText={profileFormik.touched.firstName && profileFormik.errors.firstName}
                    margin="normal"
                  />

                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={profileFormik.values.lastName}
                    onChange={profileFormik.handleChange}
                    error={profileFormik.touched.lastName && Boolean(profileFormik.errors.lastName)}
                    helperText={profileFormik.touched.lastName && profileFormik.errors.lastName}
                    margin="normal"
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    id="company"
                    name="company"
                    label="Business Name (Optional)"
                    value={profileFormik.values.company}
                    onChange={profileFormik.handleChange}
                    error={profileFormik.touched.company && Boolean(profileFormik.errors.company)}
                    helperText={profileFormik.touched.company && profileFormik.errors.company}
                    margin="normal"
                  />

                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    id="email"
                    name="email"
                    label="Email"
                    value={profileFormik.values.email}
                    onChange={profileFormik.handleChange}
                    error={profileFormik.touched.email && Boolean(profileFormik.errors.email)}
                    helperText={profileFormik.touched.email && profileFormik.errors.email}
                    margin="normal"
                  />

                  <Button sx={{ mt: 2 }} id="sign-in-button" size="large" color="primary" variant="contained" fullWidth type="submit" disabled={profileFormik.isSubmitting}>
                    NEXT
                  </Button>
                </form>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
