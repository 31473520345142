import { Grid, Button, Box } from "@mui/material";
import React, { useState, useContext } from "react";
import PageTitleBanner from "../ui-components/PageTitleBanner";
import SubscriptionCard from "../ui-components/Cards/SubscriptionCard";
import { QUERIES } from "../utils/constants";
import SubscriptionService from "../services/subscription-service";
import { useQuery } from "react-query";
import Loading from "../ui-components/Loading";
import { CheckCircle } from "@mui/icons-material";
import { Error } from "@mui/icons-material";
import { Chip } from "@mui/material";

const Subscriptions = () => {
  const { data: currentSubscription, isFetching: currentSubscriptionFetching } = useQuery([QUERIES.Subscription, "currentSubscription"], () => SubscriptionService.getMyCurrentSubscription());

  return (
    <>
      <PageTitleBanner title="Subscribe Now" />
      {currentSubscriptionFetching && <Loading />}
      <Box display={"flex"} justifyContent={"space-between"} px={2} pb={2} sx={{ backgroundColor: "#2D2D2D" }}>
        {currentSubscription?.data?.result?.active ? <Chip label="Subscribed" color="success" icon={<CheckCircle />} /> : <Chip label="Not Subscribed!" color="warning" icon={<Error />} />}
      </Box>
      <Grid container sx={{ p: 2, mt: 2, justifyContent: "center" }} spacing={1}>
        <Grid item xs={12} sm={10} md={6} lg={4}>
          <SubscriptionCard />
        </Grid>
      </Grid>
    </>
  );
};

export default Subscriptions;
