import React from "react";
import { parseISO, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const FormattedDateTime = ({ utcDateString, formatString }) => {
  if (!utcDateString) return null;

  // Ensure the UTC date string is correctly interpreted as UTC
  const utcStringWithZ = utcDateString.endsWith("Z") ? utcDateString : `${utcDateString}Z`;

  // Parse the incoming UTC string to a JavaScript Date object
  const parsedDate = parseISO(utcStringWithZ);

  // Get the user's local timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the UTC date to the local time zone
  const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

  const formattedDateTime = formatInTimeZone(parsedDate, formatString, userTimeZone);

  return (
    <>
      {formattedDateTime}
    </>
  );
};

export default FormattedDateTime;
