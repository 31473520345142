import React, { useState } from "react";
import { Grid, Typography, Divider, IconButton, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Edit, ExpandMore } from "@mui/icons-material";
import { format } from "date-fns";
import { QUERIES } from "../../utils/constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import HomeCard from "../Cards/HomeCard";
import AnnouncementService from "../../services/announcement-service";
import { useFormik } from "formik";
import Loading from "../Loading";
import FormattedDateTime from "../../utils/FormattedDateTime";

const AnnouncementCard = () => {
  const { data: currentAnnouncement, isFetching: currentAnnouncementFetching } = useQuery([QUERIES.Announcements, "current"], () => AnnouncementService.getCurrentAnnouncement());
  const { data: previousAnnouncements, isFetching: previousAnnouncementsFetching } = useQuery([QUERIES.Announcements], () => AnnouncementService.getAnnouncements());

  const createAnnouncementMutation = useMutation(async (values) => AnnouncementService.createAnnouncement(values));
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);

  const editFormik = useFormik({
    initialValues: {
      description: currentAnnouncement?.data?.result?.description,
    },
    onSubmit: async (values) => {
      await createAnnouncementMutation.mutateAsync(values);
      setIsEditing(false);
      queryClient.invalidateQueries([QUERIES.Announcements]);
    },
    enableReinitialize: true,
  });

  const isLoading = createAnnouncementMutation.isLoading || currentAnnouncementFetching || previousAnnouncementsFetching;

  return (
    <HomeCard>
      {isLoading && <Loading />}
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Typography variant="h5">ANNOUNCEMENT BANNER</Typography>
          {currentAnnouncement?.data?.result?.created && (
            <Typography variant="overline" color="text.secondary">
              LAST EDITED <FormattedDateTime utcDateString={currentAnnouncement?.data?.result?.created} formatString={"MM/dd/yy hh:mm a"} />
            </Typography>
          )}
        </Grid>
        {!isEditing && (
          <Grid item xs={2} container sx={{ justifyContent: "flex-end", alignItems: "center" }}>
            <IconButton onClick={() => setIsEditing(true)}>
              <Edit sx={{ fontSize: 32 }} />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        {!isEditing && (
          <>
            <Grid item xs={12}>
              <TextField disabled fullWidth multiline rows={4} variant="filled" value={currentAnnouncement?.data?.result?.description} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="h6">LAST 7 DAYS</Typography>
            </Grid>
            <Grid item xs={12}>
              {previousAnnouncements?.data?.result &&
                previousAnnouncements?.data?.result
                  ?.filter((announcement) => new Date(announcement.created) > new Date(new Date().setDate(new Date().getDate() - 7)))
                  ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                  .map((announcement, index) => (
                    <Accordion key={announcement?.id}>
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="body1" sx={{ width: "33%", flexShrink: 0 }}>
                          {format(new Date(announcement.created), "MM/dd/yy")}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "text.secondary" }}>
                          {announcement?.createdByUser?.firstName} {announcement?.createdByUser?.lastName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{announcement.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
            </Grid>
          </>
        )}
        {isEditing && (
          <>
            <Grid item xs={12} onSubmit={editFormik.handleSubmit} component={"form"}>
              <Grid item xs={12}>
                <TextField name="description" id="description" onChange={editFormik.handleChange} fullWidth multiline rows={4} variant="filled" value={editFormik.values.description} />
              </Grid>
              <Grid item xs={12} container justifyContent={"space-between"} mt={3}>
                <Button variant="outlined" onClick={() => setIsEditing(false)}>
                  CANCEL
                </Button>
                <Button type="submit" variant="contained">
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </HomeCard>
  );
};

export default AnnouncementCard;
