import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function EScrap({ color, ...props }) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1433_1289)">
        <path
          d="M5.63314 7.04985H18.3831V5.63318H5.63314C4.85397 5.63318 4.21647 6.27068 4.21647 7.04985V14.8415H2.7998V16.9665H12.7165V14.8415H5.63314V7.04985ZM19.0915 8.46651H14.8415C14.4519 8.46651 14.1331 8.78526 14.1331 9.17485V16.2582C14.1331 16.6478 14.4519 16.9665 14.8415 16.9665H19.0915C19.4811 16.9665 19.7998 16.6478 19.7998 16.2582V9.17485C19.7998 8.78526 19.4811 8.46651 19.0915 8.46651ZM18.3831 14.8415H15.5498V9.88318H18.3831V14.8415Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1433_1289">
          <rect width="17" height="17" fill="black" transform="translate(2.7998 2.7998)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EScrap;
