import { Box, Button, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { NumericFormat } from "react-number-format";
import ScrapHeaderCard from "../../ui-components/Cards/ScrapHeaderCard";
import { useQuery } from "react-query";
import { QUERIES } from "../../utils/constants";
import Loading from "../../ui-components/Loading";
import { useFormik } from "formik";
import { Search } from "@mui/icons-material";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import SubscribeSpeedDial from "../../ui-components/SpeedDials/SubscribeSpeedDial";
import { Link } from "react-router-dom";

const ScrapPage = ({ category, categoryTitle }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { currentClaims } = useContext(AuthContext);

  // get current metal prices
  const { data: scrapPrices, isFetching: getMetalPricesFetching } = useQuery([QUERIES.ScrapPrices], () => PriceListService.getCurrentScrapPriceList(), {
    staleTime: 1000 * 60 * 30, // 30 minutes in milliseconds
  });

  const getPricingCells = (row) => {};

  const isLoading = getMetalPricesFetching;

  const columns = useMemo(
    () => [
      {
        name: "Commodity",
        selector: (row) => row.commodity,
        sortable: true,
        wrap: true,
      },
      {
        name: "Price",
        selector: (row) => row.price,
        cell: (row) => {
          return currentClaims?.HasPricingAccess ? (
            <>
              <NumericFormat value={row.price} displayType={"text"} thousandSeparator={true} prefix={"$"} fixedDecimalScale decimalScale={2} />{" "}
              <Box component="span" sx={{ color: "text.secondary" }}>
                /{row?.unitOfMeasure}
              </Box>
            </>
          ) : (
            <Button to={`/subscriptions`} component={Link} variant="contained" size="small">
              Subscribe
            </Button>
          );
        },
        sortable: true,
      },
    ],
    []
  );

  const formik = useFormik({
    initialValues: {
      searchQuery: "",
    },
    onSubmit: (values) => {
      setSearchQuery(values.searchQuery);
    },
  });

  var scrapTypeResults = scrapPrices?.data?.result?.filter((x) => x.category == category && x.commodity.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <>
      {isLoading && <Loading />}
      <Grid container>
        <Grid item xs={12}>
          <ScrapHeaderCard title={categoryTitle} />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ pt: 2, pb: 3, px: 3, backgroundColor: "#2D2D2D", borderRadius: 0 }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                autoComplete="off"
                disabled={getMetalPricesFetching}
                autoFocus
                fullWidth
                id="searchQuery"
                name="searchQuery"
                label={`Search ${categoryTitle}`}
                margin="normal"
                variant="filled"
                value={formik.values.searchQuery}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                helperText={`Showing ${scrapTypeResults?.length} search results${searchQuery ? ` for “${searchQuery}.”` : "."}`}
              />
            </form>
          </Paper>
        </Grid>
      </Grid>

      <Box>
        <DataTable columns={columns} data={scrapTypeResults} theme="dark" dense />
      </Box>
      {!currentClaims?.HasPricingAccess && <SubscribeSpeedDial />}
    </>
  );
};

export default ScrapPage;
