import React, { useMemo, useState } from "react";
import { Grid, Typography, TextField, Paper, Divider, InputAdornment, Box, Button, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar } from "@mui/material";
import NoResultsCard from "../ui-components/NoResultsCard";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/constants";
import CoreService from "../services/core-service";
import Loading from "../ui-components/Loading";
import { Close, Search } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import SubscribeSpeedDial from "../ui-components/SpeedDials/SubscribeSpeedDial";
import { Link } from "react-router-dom";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
};

const getSecondaryAction = (description, searchText) => {
  const parts = description.split(searchText);
  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          <Typography sx={{ display: "inline" }} component="span" variant="body2">
            {part}
          </Typography>

          {index < parts.length - 1 && (
            <Typography sx={{ display: "inline", textDecoration: "underline" }} component="span" variant="body2" color="text.primary">
              {searchText}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const Cores = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { currentClaims } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      searchQuery: "",
    },
    onSubmit: (values) => {
      setSearchQuery(values.searchQuery);
    },
  });

  const { data: cores, isFetching } = useQuery([QUERIES.Cores, searchQuery], () => CoreService.getCores(searchQuery), {
    enabled: !!searchQuery,
  });

  const columns = useMemo(
    () => [
      {
        name: "Part Type",
        selector: (row) => row.category,
        cell: (row) => <Box>{row.category}</Box>,
        sortable: true,
        sortFunction: (rowA, rowB) => rowA.category.localeCompare(rowB.category),
      },
      {
        name: "Part Number",
        selector: (row) => row.partNumber,
        cell: (row) => (
          <Box>
            <Typography>{row.partNumber}</Typography>
            <Typography>{row.description}</Typography>
          </Box>
        ),
        sortable: true,
        sortFunction: (rowA, rowB) => rowA.partNumber.localeCompare(rowB.partNumber),
      },
      {
        name: "Price",
        selector: (row) => Number(row.price),
        cell: (row) =>
          currentClaims?.HasPricingAccess ? (
            <Box>${row.price}</Box>
          ) : (
            <Button to={`/subscriptions`} component={Link} variant="contained" size="small">
              Subscribe
            </Button>
          ),
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      {isFetching && <Loading />}
      <Paper elevation={0} sx={{ backgroundColor: "#2D2D2D", borderRadius: 0 }}>
        <Grid container sx={{ py: 2, px: 3 }} spacing={1}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
            <Typography variant="h5" mt={1}>
              Core Search
            </Typography>
            <Divider sx={{ borderWidth: 1, mt: 1 }} />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                autoComplete="off"
                disabled={isFetching}
                autoFocus
                required
                fullWidth
                id="searchQuery"
                name="searchQuery"
                label="Search Cores"
                margin="normal"
                variant="filled"
                value={formik.values.searchQuery}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: formik.values.searchQuery && (
                    <InputAdornment position="start" onClick={() => formik.setFieldValue("searchQuery", "")}>
                      <IconButton>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" ml={1}>
              Showing {cores?.data.result?.length} search results for “{searchQuery}”
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Grid sx={{ backgroundColor: "#2D2D2D" }}>
        <List sx={{ backgroundColor: "#424242" }}>
          {cores?.data?.result?.length > 0 &&
            cores?.data?.result?.map((core) => {
              return (
                <ListItem
                  key={`core-${core.id}`}
                  secondaryAction={
                    currentClaims?.HasPricingAccess ? (
                      <Typography
                        variant="h6"
                        sx={{
                          backgroundColor: "rgba(45, 45, 45, 0.80)",
                          borderRadius: 2,
                          padding: 1,
                        }}>
                        ${core.price}
                      </Typography>
                    ) : (
                      <Button to={`/subscriptions`} component={Link} variant="contained" size="small">
                        Subscribe
                      </Button>
                    )
                  }>
                  <ListItemText primary={core.category} secondary={getSecondaryAction(core.partNumber, searchQuery)} />
                </ListItem>
              );
            })}
        </List>
      </Grid>

      <Box sx={{ mt: 4 }}>{cores?.data?.result && cores?.data?.result?.length === 0 && <NoResultsCard />}</Box>
      {!currentClaims?.HasPricingAccess && <SubscribeSpeedDial />}
    </>
  );
};

export default Cores;
