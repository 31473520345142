import { AppBar, Box, Container, Drawer, Toolbar, Typography, Link, IconButton, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Divider, useTheme, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { IMAGES, QUERIES } from "../utils/constants";
import { Home, BarChart, SearchSharp, AccountCircle, Notifications, QuestionMarkTwoTone, ExpandMore, ExpandLess, ListAltOutlined } from "@mui/icons-material";
import { useQuery } from "react-query";
import UserService from "../services/user-service";
import ApexAnnouncementAlert from "./Alerts/ApexAnnouncementAlert";
import MenuIcon from "../icons/MenuIcon";
import { Percent } from "@mui/icons-material";
import AnnouncementService from "../services/announcement-service";
import { useDispatch, useSelector } from "react-redux";
import { setDismissedId } from "../slices/announcementSlice";
import Badge from "@mui/material/Badge";
import { LEGAL_LINKS } from "../utils/constants";
import Collapse from "@mui/material/Collapse";
const drawerWidth = 240;

const liStyling = {
  "&.Mui-selected": { borderRadius: "2px", background: "rgba(15, 151, 79, 0.30)" },
};

export const PageLayout = (props) => {
  const { currentUser, currentClaims } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const belowMedium = useMediaQuery(theme.breakpoints.down("md"));
  const { data: userData, isFetching: getMeFetching } = useQuery([QUERIES.Users, "me"], () => UserService.getMe());
  const { data: currentAlert } = useQuery([QUERIES.Announcements, "current"], () => AnnouncementService.getCurrentAnnouncement());
  const { lastDismissedId } = useSelector((state) => state.announcement);
  const { uniqueId } = useSelector((state) => state.device);
  const dispatch = useDispatch();
  const [openScrap, setOpenScrap] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const isDismissed = currentAlert?.data?.result?.id && currentAlert.data.result.id === lastDismissedId;

  const navItems = [
    {
      name: "Home",
      path: "/",
      icon: <Home />,
    },
    {
      name: "Search",
      path: "",
      icon: <SearchSharp />,
      children: [
        { name: "Converters", path: "/converters" },
        { name: "Cores", path: "/cores" },
      ],
    },
    {
      name: "Markets",
      path: "/markets",
      icon: <BarChart />,
    },
    {
      name: "Scrap Lists",
      path: "",
      icon: <ListAltOutlined />,
      children: [
        { name: "Ferrous", path: "/ferrous" },
        { name: "Non-Ferrous", path: "/non-ferrous" },
        { name: "E-Scrap", path: "/e-scrap" },
      ],
    },
    {
      name: "Margin",
      path: "/margin",
      icon: <Percent />,
      disabled: !currentClaims?.HasPricingAccess,
    },
    {
      name: "Account",
      path: "/account",
      icon: <AccountCircle />,
    },
  ];

  const handleClick = (dropdown) => {
    if (dropdown === "Scrap Lists") {
      setOpenScrap(!openScrap);
    } else if (dropdown === "Search") {
      setOpenSearch(!openSearch);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dismissAnnouncement = () => {
    if (isDismissed || !currentAlert?.data?.result?.id) {
      dispatch(setDismissedId(null));
    } else if (currentAlert?.data?.result?.id) {
      dispatch(setDismissedId(currentAlert.data.result.id));
    }
  };

  useEffect(() => {
    if (mobileOpen) setMobileOpen(false);
  }, [belowMedium]);

  var notification = <Notifications />;

  if (!isDismissed && currentAlert?.data?.result?.id) {
    notification = (
      <Badge badgeContent={1} color="warning">
        <Notifications />
      </Badge>
    );
  }

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "center",
        pt: 3,
        px: 1,
        width: drawerWidth,
        height: "100%",
        backgroundColor: "#212121",
      }}>
      <Link component={RouterLink} to="/" color="inherit">
        <Box component="img" src={IMAGES.LOGO_ICON_ONLY_LIGHT} sx={{ height: 60, mt: 1 }} />
      </Link>
      <Divider sx={{ m: 2, borderColor: "primary.outlinedBorder" }} />
      <List>
        {navItems.map((item) => (
          <React.Fragment key={item.name}>
            <ListItem disablePadding>
              <ListItemButton
                sx={liStyling}
                selected={location.pathname === item.path}
                onClick={() => {
                  handleClick(item.name);
                  if (item.name !== "Scrap Lists" && item.name !== "Search") {
                    handleDrawerToggle();
                  }
                }}
                LinkComponent={item.children ? undefined : RouterLink}
                to={item.children ? undefined : item.path}
                disabled={item.disabled}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
                {item.name === "Scrap Lists" && (openScrap ? <ExpandLess /> : <ExpandMore />)}
                {item.name === "Search" && (openSearch ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            </ListItem>
            {item.name === "Scrap Lists" && item.children && (
              <Collapse in={openScrap} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((child) => (
                    <ListItem key={child.name} disablePadding>
                      <ListItemButton sx={liStyling} selected={location.pathname === child.path} LinkComponent={RouterLink} to={child.path} disabled={child.disabled} onClick={handleDrawerToggle}>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={child.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
            {item.name === "Search" && item.children && (
              <Collapse in={openSearch} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((child) => (
                    <ListItem key={child.name} disablePadding>
                      <ListItemButton sx={liStyling} selected={location.pathname === child.path} LinkComponent={RouterLink} to={child.path} disabled={child.disabled} onClick={handleDrawerToggle}>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={child.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                  {currentClaims?.IsAdmin && (
                    <>
                      <ListItem key="price-lists" disablePadding>
                        <ListItemButton sx={liStyling} selected={location.pathname === "/admin/price-lists"} LinkComponent={RouterLink} to="/admin/price-lists" onClick={handleDrawerToggle}>
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary="Price Lists" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem key="admin" disablePadding>
                        <ListItemButton sx={liStyling} selected={location.pathname === "/admin/users"} LinkComponent={RouterLink} to="/admin/users" onClick={handleDrawerToggle}>
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary="Users" />
                        </ListItemButton>
                      </ListItem>
                    </>
                  )}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
      <Box
        sx={{
          mt: "auto",
          gap: 1,
          mb: 1,
          flexDirection: "column",
          display: "flex",
        }}>
        <Typography variant="caption">{currentUser?.email}</Typography>
        <Typography variant="overline" color="text.secondary">
          <Link href={LEGAL_LINKS.PRIVACY_POLICY} target="_blank" rel="noopener">
            PRIVACY POLICY
          </Link>
        </Typography>
        <Typography variant="overline" color="text.secondary">
          <Link href={LEGAL_LINKS.TERMS_AND_CONDITIONS} target="_blank" rel="noopener">
            TERMS & CONDITIONS
          </Link>
        </Typography>
        <Box sx={{ color: "text.disabled", display: "flex", alignItems: "center", gap: 1, justifyContent: "center" }}>
          <IconButton color="inherit">
            <QuestionMarkTwoTone />
          </IconButton>
          <Box>
            <Typography fontSize={12} textAlign="start">
              V{process.env.REACT_APP_VERSION}
            </Typography>
            <Typography fontSize={10} textAlign="start">
              {uniqueId?.slice(-12)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100VH",
          backgroundImage: isDesktop ? `url(${IMAGES.BACKGROUND_CONTAINER_DESKTOP})` : `url(${IMAGES.BACKGROUND_CONTAINER_MOBILE})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <AppBar
          component="nav"
          position="fixed"
          sx={{
            backgroundColor: "#212121",
            border: 0,
            borderBottom: "1px solid rgba(129, 199, 132, 0.5)",
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
          }}
          variant="outlined"
          elevation={0}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
              <IconButton size="large" color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { md: "none" } }}>
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link component={RouterLink} to="/" color="inherit">
                  <Box component="img" src={IMAGES.LOGO_LIGHT} sx={{ maxHeight: isDesktop ? 32 : 26 }} />
                </Link>
              </Box>
              <IconButton size="large" color="inherit" aria-label="toggle notification" edge="start" onClick={dismissAnnouncement}>
                {notification}
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}>
            {drawer}
          </Drawer>
          <Drawer
            open
            elevation={2}
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": { boxSizing: "border-box" },
            }}>
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, width: { md: `calc(100% - ${drawerWidth}px)` } }}>
          <Toolbar />
          <Container disableGutters maxWidth={false}>
            {props.children}
          </Container>
        </Box>
      </Box>
      <ApexAnnouncementAlert />
    </>
  );
};
