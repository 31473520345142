import api from "../utils/axios-client";

/**
 * Gets current subscription
 *
 * @returns {Promise} A promise that resolves to current user subscription.
 */
const getMyCurrentSubscription = async () => api.get("v1/subscriptions/current");

/**
 * Generates a client secret for a Stripe checkout session
 *
 * @param {string} stripePriceId - The stripe pricing ID for the product to create a checkout session for.
 * @returns {Promise} A promise that resolves to a Stripe checkout session client secret.
 */
const createStripeCheckoutSession = async (stripePriceId) => api.get("v1/subscriptions/stripe/create-checkout-session", { params: { stripePriceId } });

/**
 * Creates a stripe portal session and returns a URL to redirect the user to the Stripe portal.
 *
 * @returns {Promise} A promise that resolves to the URL of the portal session
 */
const createStripePortalSession = async () => api.get("v1/subscriptions/stripe/create-portal-session");

const SubscriptionService = {
  getMyCurrentSubscription,
  createStripeCheckoutSession,
  createStripePortalSession,
};

export default SubscriptionService;
