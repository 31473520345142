import React, { useState, useEffect, useContext } from "react";
import PageTitleBanner from "../../ui-components/PageTitleBanner";
import { Grid, TextField, Button, Box, Alert, Chip, Typography } from "@mui/material";
import UserService from "../../services/user-service";
import { useFormik } from "formik";
import { PatternFormat } from "react-number-format";
import * as yup from "yup";
import Loading from "../../ui-components/Loading";
import { QUERIES } from "../../utils/constants";
import Modal from "../../ui-components/Modal";
import CatIcon from "../../icons/CatIcon";
import { CheckCircle } from "@mui/icons-material";
import { Error } from "@mui/icons-material";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ContactService from "../../services/contact-service";
import SubscriptionService from "../../services/subscription-service";
import { ArrowForwardIos } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

const profileValidationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  company: yup.string(),
  email: yup.string().email("Please enter a valid email address").required("Email is required"),
  phoneNumber: yup.string().length(10, "Phone number must be 10 digits").required("Phone number is required"),
});

const Profile = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // get user profile
  const { data: userProfile, isFetching: getMeFetching } = useQuery([QUERIES.Users, "me"], () => UserService.getMe());
  const { data: currentSubscription, isFetching: currentSubscriptionFetching } = useQuery([QUERIES.Subscription, "currentSubscription"], () => SubscriptionService.getMyCurrentSubscription());

  const handlePortal = async () => {
    SubscriptionService.createStripePortalSession().then((res) => {
      window.location.href = res.data.result;
    });
  };

  const updateProfileRequest = async (values) => {
    const message = `<p>User <b>${userProfile?.data?.result?.email}</b>, is trying to <b>change their profile:</b></p>
      <p>First Name: ${userProfile?.data?.result?.firstName} -> ${values.firstName}</p>
      <p>Last Name: ${userProfile?.data?.result?.lastName} -> ${values.lastName}</p>
      <p>Company: ${userProfile?.data?.result?.company} -> ${values.company}</p>
      <p>Email: ${userProfile?.data?.result?.email} -> ${values.email}</p>
      <p>Phone Number: ${userProfile?.data?.result?.phoneNumber} -> ${values.phoneNumber}</p>
      `;

    const response = await ContactService.sendMessage({ message: message });
  };

  const mutation = useMutation(updateProfileRequest);

  // Profile form
  const profileFormik = useFormik({
    initialValues: {
      ...userProfile?.data?.result,
    },
    profileValidationSchema,
    onSubmit: async (values) => {
      await mutation.mutateAsync(values);
      handleModalOpen();
    },
    enableReinitialize: true,
  });

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    navigate("/");
  };

  const isLoading = profileFormik.isSubmitting || getMeFetching || mutation.isLoading || currentSubscriptionFetching;

  return (
    <>
      <PageTitleBanner title="Profile" />
      <Box display={"flex"} justifyContent={"space-between"} px={2} pb={2} sx={{ backgroundColor: "#2D2D2D" }}>
        {currentSubscription?.data?.result?.active ? (
          <>
            <Chip label="Subscribed" color="success" icon={<CheckCircle />} />
            <Button endIcon={<ArrowForwardIos sx={{ color: "#FFF" }} />} size="small" onClick={handlePortal}>
              <Typography color="#FFF">Manage Subscription</Typography>
            </Button>
          </>
        ) : (
          <>
            <Chip label="Not Subscribed!" color="warning" icon={<Error />} />
            <Button endIcon={<ArrowForwardIos sx={{ color: "#FFF" }} />} size="small" component={Link} to={"/subscriptions"}>
              <Typography color="#FFF">Subscribe Now</Typography>
            </Button>
          </>
        )}
      </Box>
      <Grid container sx={{ p: 2, mt: 2 }}>
        <Grid item sm={1} md={3} lg={4}></Grid>
        <Grid item xs={12} sm={10} md={6} lg={4}>
          {isLoading && <Loading />}

          <Box
            component={"form"}
            onSubmit={profileFormik.handleSubmit}
            sx={{
              backgroundColor: "#2D2D2D",
              borderWidth: "2px",
              borderColor: "primary.main",
              borderStyle: "solid",
              borderRadius: "4px",
              py: 3,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>
            {profileFormik.errors.submit && (
              <Alert severity="error" variant="outlined">
                <strong>Error: </strong>
                {profileFormik.errors.submit}
              </Alert>
            )}
            <TextField
              required
              fullWidth
              variant="filled"
              id="firstName"
              name="firstName"
              label="First Name"
              value={profileFormik.values.firstName}
              onChange={profileFormik.handleChange}
              error={profileFormik.touched.firstName && Boolean(profileFormik.errors.firstName)}
              helperText={profileFormik.touched.firstName && profileFormik.errors.firstName}
            />

            <TextField
              required
              fullWidth
              variant="filled"
              id="lastName"
              name="lastName"
              label="Last Name"
              value={profileFormik.values.lastName}
              onChange={profileFormik.handleChange}
              error={profileFormik.touched.lastName && Boolean(profileFormik.errors.lastName)}
              helperText={profileFormik.touched.lastName && profileFormik.errors.lastName}
            />

            <TextField
              fullWidth
              variant="filled"
              id="company"
              name="company"
              label="Business Name (Optional)"
              value={profileFormik.values.company}
              onChange={profileFormik.handleChange}
              error={profileFormik.touched.company && Boolean(profileFormik.errors.company)}
              helperText={profileFormik.touched.company && profileFormik.errors.company}
            />

            <TextField
              required
              fullWidth
              variant="filled"
              id="email"
              name="email"
              label="Email"
              value={profileFormik.values.email}
              onChange={profileFormik.handleChange}
              error={profileFormik.touched.email && Boolean(profileFormik.errors.email)}
              helperText={profileFormik.touched.email && profileFormik.errors.email}
            />

            <PatternFormat
              required
              name="phoneNumber"
              id="phoneNumber"
              value={profileFormik.values.phoneNumber}
              onValueChange={(values) => {
                const { value } = values;
                profileFormik.setFieldValue("phoneNumber", value);
              }}
              format="+# (###) ### ####"
              allowEmptyFormatting
              mask="_"
              customInput={TextField}
              label="Phone Number"
              variant="filled"
              error={profileFormik.touched.phoneNumber && Boolean(profileFormik.errors.phoneNumber)}
              helperText={profileFormik.touched.phoneNumber && profileFormik.errors.phoneNumber}
              fullWidth
            />

            <Button id="sign-in-button" size="large" color="primary" variant="contained" fullWidth type="submit" disabled={isLoading || !profileFormik.dirty}>
              Request Profile Change
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={open}
        handleModalClose={handleModalClose}
        title={"Profile Change Request"}
        description={"Your request to edit your profile has been sent. A member of our team will reach out to you shortly."}
        icon={<CatIcon sx={{ width: 40, height: 40 }} />}
        buttons={[
          <Button size="large" onClick={handleModalClose} color="primary" fullWidth variant="contained">
            Return Home
          </Button>,
        ]}
      />
    </>
  );
};

export default Profile;
