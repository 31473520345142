import React, { useState } from "react";
import { Card, CardContent, Divider, Button, CardHeader, List, Box, IconButton, ListItemIcon, Dialog, Typography, DialogContent, useMediaQuery, useTheme, DialogTitle } from "@mui/material";
import StripeCheckout from "../StripeCheckout";
import { useQueryClient } from "react-query";
import { QUERIES } from "../../utils/constants";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const StripeCheckoutDialog = ({ open, handleClose }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSubscriptionCompleted = () => {
    queryClient.invalidateQueries(QUERIES.Subscription);
    handleClose();
    navigate(-1);
  };

  return (
    <Dialog open={open} aria-labelledby="browse-image-library-dialog-title" fullWidth={true} maxWidth="sm" fullScreen={!isDesktop} PaperProps={{ backgroundColor: "#404042" }}>
      <DialogTitle id="browse-image-library-dialog-title" sx={{ backgroundColor: "#404042" }}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Typography variant="h5">Subscription</Typography>
        <Typography variant="body1" color={"text.secondary"}>
          Check Out
        </Typography>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent sx={{ px: 2, py: 0.5, backgroundColor: "#404042" }}>
        <StripeCheckout onCompleted={onSubscriptionCompleted} />
      </DialogContent>
    </Dialog>
  );
};

export default StripeCheckoutDialog;
