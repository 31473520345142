import api from "../utils/axios-client";

const getCurrentAnnouncement = async () => api.get("v1/announcements/current");

const getAnnouncements = async () => api.get("v1/announcements");

/**
 * Creates an announcement.
 *
 * @param {Object} announcement - The announcement data.
 * @param {string} announcement.description - The description of the announcement.
 * @returns {Promise} A promise that resolves to the created announcement.
 */
const createAnnouncement = async (announcement) => api.post("v1/announcements", announcement);

const AnnouncementService = {
  getCurrentAnnouncement,
  getAnnouncements,
  createAnnouncement,
};

export default AnnouncementService;
