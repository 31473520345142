import React, { useContext } from "react";
import { Grid, Typography, Box, Card, CardContent, Button } from "@mui/material";
import { IMAGES, MARGIN_TYPES } from "../utils/constants";
import { NumericFormat } from "react-number-format";
import { PhotoOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { AuthContext } from "../contexts/AuthContext";
import AdminUploadButton from "./Buttons/AdminUploadButton";
import { Link } from "react-router-dom";

const imageKeys = ["mainImage", "frontImage", "backImage", "codeImage", "shieldImage", "withoutShieldImage"];

const ConverterCard = ({ converter }) => {
  const { marginType, marginValue } = useSelector((state) => state.margin);
  const { currentClaims } = useContext(AuthContext);

  let newPrice;
  if (marginType === MARGIN_TYPES.DOLLAR) {
    newPrice = Math.floor(converter.price - marginValue);
  } else {
    newPrice = Math.floor(converter.price - (converter.price * marginValue) / 100);
  }
  newPrice = newPrice.toFixed(2);

  const extractedImages = imageKeys
    .filter((key) => converter[key]?.watermarkUrl)
    .map((key) => ({
      url: converter[key]?.watermarkUrl,
      thumbnailUrl: converter[key]?.thumbnailUrl,
    }));

  // Adding extra images
  const extraImages = converter?.extraImages?.map((image) => ({
    url: image?.watermarkUrl,
    thumbnailUrl: image.thumbnailUrl,
  }));

  const allImages = [...extractedImages, ...extraImages];

  if (allImages.length < 4) {
    for (let i = allImages.length; i < 4; i++) {
      allImages.push({ url: null, thumbnailUrl: null });
    }
  }

  return (
    <>
      <Card
        sx={{
          mx: 1,
          mb: 1,
          pb: 2,
          backgroundColor: "#2D2D2D",
          border: "1px solid #81C784",
          height: "100%",
        }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ position: "relative", backgroundColor: "black", borderBottom: "1px solid #81C784" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Zoom zoomImg={{ src: allImages[0]?.url ?? IMAGES.CONVERTER_IMAGE_COMING_SOON }}>
                  <Box component="img" src={allImages[0]?.thumbnailUrl ?? IMAGES.CONVERTER_IMAGE_COMING_SOON} sx={{ height: 220 }} />
                </Zoom>
              </Box>
              {currentClaims?.IsAdmin && <AdminUploadButton converter={converter} />}
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" alignContent="end">
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", height: 100 }}>
                {allImages?.slice(1, 4)?.map((image, index) => {
                  var content = <PhotoOutlined sx={{ height: 48, width: 48 }} />;

                  if (image.url)
                    content = (
                      <Zoom zoomImg={{ src: image.url }}>
                        <Box component="img" src={image.thumbnailUrl} sx={{ height: 40, width: 40 }} />
                      </Zoom>
                    );

                  return (
                    <Box key={`converter-image-${index}`} sx={{ p: 1, border: "1px solid #FFFFFF80", borderRadius: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {content}
                    </Box>
                  );
                })}
              </Box>
              <Box textAlign="center" mt={2}>
                <Typography variant="body1">{converter.name}</Typography>
                {currentClaims?.HasPricingAccess ? (
                  <>
                    <Typography variant="h6">
                      <NumericFormat value={newPrice} displayType={"text"} thousandSeparator={true} prefix="$" fixedDecimalScale decimalScale={2} />
                    </Typography>
                  </>
                ) : (
                  <>
                    <Box sx={{ mx: 3 }}>
                      <Typography variant="h6">Subscribe for Price</Typography>
                      <Button to={`/subscriptions`} size="large" component={Link} variant="contained" fullWidth sx={{ mt: 2 }}>
                        Subscribe
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ConverterCard;
