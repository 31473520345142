import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

function getSavedUniqueId() {
  let savedId = localStorage.getItem("deviceUniqueId");
  if (!savedId) {
    savedId = uuidv4();
    localStorage.setItem("deviceUniqueId", savedId);
  }
  return savedId;
}
const initialState = {
  uniqueId: getSavedUniqueId(),
};
const deviceSlice = createSlice({
  name: "device",
  initialState,
});

export const {} = deviceSlice.actions;

export default deviceSlice.reducer;
