import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function NonFerrousScrap({ color, ...props }) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <rect x="3" y="3" width="13" height="19" fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_1433_1281" transform="scale(0.0192308 0.0131579)" />
        </pattern>
        <image
          id="image0_1433_1281"
          width="52"
          height="76"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABMCAYAAAAiCZRHAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACiSURBVHgB7dtBCoAwDADBVPz/l/XsrdY2rnHnBwsphKAt1jsi0RbFGERnEJ1BdAbRtcg3e3O4NDhydAbRGURnEJ1BdAbRlQvao3/7fWMzv82Royv5hnql3qhHOXJ0BtEZRGcQnUF0BtEZRGcQnUF0v74pzLrLLb1NOHJ0Jd/QJ27WvRw5OoPoDKIziM4gOoPo/G57gH94PWEQnUF0BtEZRHcC0Q0GnI3vVxoAAAAASUVORK5CYII="
        />
      </defs>
    </SvgIcon>
  );
}

export default NonFerrousScrap;
