import React, { useState } from "react";
import { Card, CardContent, Divider, Button, CardHeader, List, ListItem, ListItemText, ListItemIcon, Dialog, Typography, DialogContent, useMediaQuery, useTheme, DialogTitle } from "@mui/material";
import CatIcon from "../../icons/CatIcon";
import StripeCheckoutDialog from "../Dialogs/StripeCheckoutDialog";

const SubscriptionCard = (props) => {
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          p: 1,
          backgroundColor: "#2d2d2d",
          borderColor: "primary.outlinedBorder",
          borderWidth: 1,
          mb: 2,
          borderRadius: 1,
          flex: 1,
        }}>
        <CardHeader title="Subscription" subheader="$29.99/mo" titleTypographyProps={{ textAlign: "center" }} subheaderTypographyProps={{ textAlign: "center", variant: "h6", mt: 1, px: 4, mb: 2 }}></CardHeader>
        <Divider color="primary" />
        <CardContent sx={{ px: 2 }}>
          <List>
            <ListItem>
              <ListItemIcon>
                <CatIcon />
              </ListItemIcon>
              <ListItemText primary="Get Instant Access" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CatIcon />
              </ListItemIcon>
              <ListItemText primary="Commodities Pricing" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CatIcon />
              </ListItemIcon>
              <ListItemText primary="Catalytic Converter Pricing" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CatIcon />
              </ListItemIcon>
              <ListItemText primary="Core Pricing" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CatIcon />
              </ListItemIcon>
              <ListItemText primary="Scrap Pricing" />
            </ListItem>
          </List>
          <Button variant="contained" fullWidth size="large" onClick={handleModalOpen}>
            Subscribe
          </Button>
        </CardContent>
      </Card>
      <StripeCheckoutDialog open={open} handleClose={handleModalClose} />
    </>
  );
};

export default SubscriptionCard;
