import { createSlice } from "@reduxjs/toolkit";
import { MARGIN_TYPES } from "../utils/constants";

const initialState = {
  marginValue: 0,
  marginType: MARGIN_TYPES.PERCENTAGE,
};

const marginSlice = createSlice({
  name: "margin",
  initialState,
  reducers: {
    setMargin: (state, action) => {
      state.marginValue = action.payload.marginValue;
      state.marginType = action.payload.marginType;
    },
  },
});

export const { setMargin } = marginSlice.actions;

export default marginSlice.reducer;
