import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function FerrousScrap({ color, ...props }) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 17.01V19H11V22H13V19H20V17.01H4ZM7 14H17V16H19V8.69L17.11 3H6.89L5 8.69V16H7V14ZM9 11.5C8.45 11.5 8 11.05 8 10.5C8 9.95 8.45 9.5 9 9.5C9.55 9.5 10 9.95 10 10.5C10 11.05 9.55 11.5 9 11.5ZM15 11.5C14.45 11.5 14 11.05 14 10.5C14 9.95 14.45 9.5 15 9.5C15.55 9.5 16 9.95 16 10.5C16 11.05 15.55 11.5 15 11.5ZM8.33 5H15.67L16.33 7H7.67L8.33 5Z"
        fill="black"
      />
    </SvgIcon>
  );
}

export default FerrousScrap;
