import api from "../utils/axios-client";

const getCatalyticConverters = async (search) => api.get("v1/catalyticconverters", { params: { search } });

const getAdminCatalyticConverters = async (search) => api.get(`v1/catalyticconverters/admin`, { params: { search } });

/**
 * Uploads a catalytic converter image.
 *
 * @param {string} converterId - The catalytic converter id.
 * @param {Object} image - The catalytic converter image.
 * @param {number} image.imageId - The catalytic converter image id.
 * @param {number} image.converterImageTypeId - The catalytic converter image type id.
 */
const assignImage = async (converterId, image) => {
  return api.post(`v1/catalyticconverters/${converterId}/assign-image`, image);
};

const CatalyticConverterService = {
  getCatalyticConverters,
  getAdminCatalyticConverters,
  assignImage,
};

export default CatalyticConverterService;
