import React, { useEffect, useState, createContext } from "react";
import { auth } from "../utils/firebase";
import { signOut } from "firebase/auth";
import { Box, Fade, useMediaQuery, useTheme } from "@mui/material";
import { EVENT_TYPES, IMAGES } from "../utils/constants";
import EventService from "../services/event-service";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [currentUser, setCurrentUser] = useState(null);
  const [currentClaims, setCurrentClaims] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      if (user) {
        user.getIdTokenResult().then((idToken) => {
          setCurrentClaims(idToken?.claims);
        });
      } else {
        setCurrentClaims(null);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000); // 1000 milliseconds = 1 second
      // setLoading(false);
    });

    return unsubscribe;
  }, []);

  const logOut = async () => {
    try {
      try {
        EventService.createEvent({ typeId: EVENT_TYPES.LOGOUT });
      } catch (error) {
        console.error(error);
      }

      await signOut(auth);
    } catch (error) {
      console.error("Sign out error:", error.message);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, currentClaims, logOut, setCurrentClaims }}>
      {loading && (
        <Fade in={loading} timeout={1000}>
          <Box
            sx={{
              position: "absolute",
              height: "100vh",
              width: "100vw",
              background: isDesktop ? `url(${IMAGES.BACKGROUND_DESKTOP})` : `url(${IMAGES.BACKGROUND_MOBILE})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Box component={"img"} src={IMAGES.LOGO_LIGHT} sx={{ maxHeight: 150, maxWidth: "80%" }} />
          </Box>
        </Fade>
      )}

      {!loading && children}
    </AuthContext.Provider>
  );
};
