import React from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }} open={true}>
      <CircularProgress size={120} color="inherit" />
    </Backdrop>
  );
};

export default Loading;
