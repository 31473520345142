import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { IMAGES } from "../utils/constants";
import CatIcon from "../icons/CatIcon";
import { Link } from "react-router-dom";

const NoResultsCard = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#2d2d2d", p: 2, border: 1, borderRadius: 1, borderColor: "primary.main", maxWidth: 342, mx: "auto" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", py: 2 }}>
          <CatIcon sx={{ width: 40, height: 40 }} />
          <Box component={"img"} src={IMAGES.LOGO_LIGHT} sx={{ height: 30 }} />
          <CatIcon sx={{ width: 40, height: 40 }} />
        </Box>
        <Divider sx={{ mt: 1, mb: 2, backgroundColor: "primary.main" }} />
        <Typography sx={{ my: 2 }} variant="body1">
          Having trouble finding what you're looking for?
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Link to="/account/contact" variant="button">
            <Button size="large" fullWidth variant="contained">
              Contact Us
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default NoResultsCard;
