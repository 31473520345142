import React, { useState, useEffect } from "react";
import { Grid, Divider, Switch, Typography, Box, Slider, TextField, Button } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { setMargin } from "../slices/marginSlice";
import { enqueueSnackbar } from "notistack";
import { MARGIN_TYPES } from "../utils/constants";

const Margin = () => {
  const { marginType, marginValue } = useSelector((state) => state.margin);
  const [marginInput, setMarginInput] = useState(marginValue);

  const dispatch = useDispatch();

  useEffect(() => {
    setMarginInput(marginValue);
  }, [marginValue]);

  const handleSwitchChange = (event) => {
    dispatch(setMargin({ marginValue: marginInput, marginType: event.target.checked ? MARGIN_TYPES.DOLLAR : MARGIN_TYPES.PERCENTAGE }));
  };

  const handleMarginChange = (event, newValue) => {
    dispatch(setMargin({ marginValue: newValue, marginType: marginType }));
  };

  const handleMarginInputChange = (event) => {
    setMarginInput(event.target.value);
  };

  const handleMarginSet = (value) => {
    dispatch(setMargin({ marginValue: Number(marginInput), marginType: marginType }));
    enqueueSnackbar("Margin set to $" + marginInput, { variant: "success" });
  };

  return (
    <>
      <Grid container sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: "#2D2D2D",
            p: 3,
          }}>
          <Typography variant="h5">Margin</Typography>
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Switch onChange={handleSwitchChange} checked={marginType === MARGIN_TYPES.DOLLAR} />
            {marginType === MARGIN_TYPES.DOLLAR ? <Typography variant="body1">Dollar</Typography> : <Typography variant="body1">Percentage</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 3,
              backgroundColor: "#2d2d2d",
              alignItems: "center",
              border: (theme) => `1px solid ${theme.palette.primary.outlinedBorder}`,
              borderRadius: 1,
              gap: 1,
            }}>
            <Typography variant="h6">Current Margin</Typography>
            <Typography variant="body2" color="text.secondary">
              (the margin you make on our prices)
            </Typography>
            {marginType === MARGIN_TYPES.DOLLAR ? (
              <>
                <NumericFormat customInput={TextField} decimalScale={2} id="marginInput" name="marginInput" label="Dollar" autoFocus required fullWidth variant="filled" sx={{ mt: 2 }} value={marginInput} onChange={handleMarginInputChange} />
                <Button variant="contained" size="large" fullWidth sx={{ my: 2 }} onClick={handleMarginSet}>
                  Set
                </Button>
              </>
            ) : (
              <>
                <Typography variant="h6">{marginValue}%</Typography>
                <Slider max={30} sx={{ mt: 2 }} onChange={handleMarginChange} value={marginValue} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    mb: 2,
                  }}>
                  <Typography variant="body1">0%</Typography>
                  <Typography variant="body1">30%</Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Margin;
