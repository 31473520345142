import React, { useState } from "react";
import { Grid, Typography, Box, IconButton, Divider, Dialog, DialogTitle, DialogContent, Button, DialogActions, useTheme, useMediaQuery, CircularProgress, TextField, InputAdornment, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { CATALYTIC_CONVERTER_IMAGE_TYPES, QUERIES } from "../../utils/constants";
import { Check, Search } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import CatalyticConverterService from "../../services/catalytic-converter-service";
import Loading from "../Loading";
import { enqueueSnackbar } from "notistack";
import ImageService from "../../services/image-service";
import { useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";

const steps = {
  SEARCH: 1,
  ASSIGN: 2,
};

const ImageLibraryDialog = ({ converter, onClose }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const confirm = useConfirm();

  const [converterImageTypeId, setConverterImageTypeId] = useState(CATALYTIC_CONVERTER_IMAGE_TYPES.MAIN);
  const [existingImages, setExistingImages] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [step, setStep] = useState(steps.SEARCH);

  const queryClient = useQueryClient();

  const assignImageMutation = useMutation({
    mutationFn: (image) => CatalyticConverterService.assignImage(converter?.id, image),
  });

  const handleUploadImage = async () => {
    confirm({
      title: "Are you sure?",
      description: "Are you sure you want to assign this image?",
      confirmationText: "YES, ASSIGN",
      confirmationButtonProps: {
        variant: "contained",
        color: "primary",
      },
      cancellationButtonProps: {
        variant: "outlined",
        color: "primary",
      },
      //   cancellationText: "No",
      dialogActionsProps: {
        sx: {
          justifyContent: "space-between",
        },
      },
    })
      .then(async () => {
        await assignImageMutation.mutateAsync({
          converterImageTypeId: converterImageTypeId,
          imageId: selectedImage?.id,
        });

        queryClient.invalidateQueries(QUERIES.Converters);
        queryClient.invalidateQueries(QUERIES.Images);

        enqueueSnackbar("Image Assigned", { variant: "success" });

        onClose();
      })
      .catch(() => {
        // pass
      });
  };

  const searchImageFormik = useFormik({
    initialValues: {
      searchQuery: converter?.name,
    },
    onSubmit: async (values) => {
      var searchResult = await ImageService.getImages(values.searchQuery);

      setExistingImages(searchResult?.data?.result);
    },
  });

  const handleNext = () => {
    // onClose(selectedImage);
    if (step === steps.SEARCH) {
      setStep(steps.ASSIGN);
    } else if (step === steps.ASSIGN) {
      handleUploadImage();
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const renderSearchStep = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <form onSubmit={searchImageFormik.handleSubmit}>
            <TextField
              autoComplete="off"
              autoFocus
              required
              fullWidth
              id="searchQuery"
              name="searchQuery"
              label="Search Images"
              margin="normal"
              variant="filled"
              value={searchImageFormik.values.searchQuery}
              onChange={searchImageFormik.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
        {searchImageFormik.isSubmitting && (
          <Grid item xs={12} mt={1}>
            <CircularProgress size={32} color="inherit" />
            <Typography>Searching...</Typography>
          </Grid>
        )}
        {existingImages?.length > 0 &&
          existingImages.map((image) => (
            <Grid
              item
              xs={3}
              key={`image-${image.id}`}
              sx={{ display: "flex", justifyContent: "center", position: "relative" }}
              onClick={() => {
                if (selectedImage?.id === image.id) {
                  setSelectedImage(null);
                } else {
                  setSelectedImage(image);
                }
              }}>
              {selectedImage?.id === image.id && (
                <IconButton sx={{ position: "absolute", top: 8, left: 8, p: 1, backgroundColor: "primary.main" }}>
                  <Check fontSize="small" color="white" />
                </IconButton>
              )}
              <Box component="img" src={image.thumbnailUrl} sx={{ maxHeight: 100 }} />
            </Grid>
          ))}
      </Grid>
    );
  };

  const handleRadioChange = (event) => {
    setConverterImageTypeId(parseInt(event.target.value));
  };

  const renderRadio = (converterImageType, label, hasImage = false) => {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <FormControlLabel value={converterImageType} control={<Radio />} label={label} />
        <Typography variant="body2">{hasImage ? "Has Image" : "No Image"}</Typography>
      </Box>
    );
  };

  const renderAssignStep = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary">
            This will replace the current image assigned
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="img" src={selectedImage?.watermarkUrl} sx={{ maxHeight: 240 }} />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="body1" color="text.secondary">
            Select where you’d like to assign this image to
          </Typography>
          <RadioGroup name="image-type-radio-buttons-group" value={converterImageTypeId} onChange={handleRadioChange}>
            {renderRadio(CATALYTIC_CONVERTER_IMAGE_TYPES.MAIN, "Main", converter?.mainImage)}
            {renderRadio(CATALYTIC_CONVERTER_IMAGE_TYPES.FRONT, "Front", converter?.frontImage)}
            {renderRadio(CATALYTIC_CONVERTER_IMAGE_TYPES.CODE, "Code", converter?.codeImage)}
            {renderRadio(CATALYTIC_CONVERTER_IMAGE_TYPES.BACK, "Back", converter?.backImage)}
            {renderRadio(CATALYTIC_CONVERTER_IMAGE_TYPES.SHIELD, "Shield", converter?.shieldImage)}
            {renderRadio(CATALYTIC_CONVERTER_IMAGE_TYPES.WITHOUT_SHIELD, "Without Shield", converter?.withoutShieldImage)}
            {renderRadio(CATALYTIC_CONVERTER_IMAGE_TYPES.EXTRA, "Extra", converter?.extraImages)}
          </RadioGroup>
        </Grid>
      </Grid>
    );
  };

  const isLoading = searchImageFormik.isSubmitting || assignImageMutation.isLoading;

  const isNextButtonDisabled = (step === steps.SEARCH && selectedImage === null) || (step === steps.ASSIGN && converterImageTypeId === null);
  const dialogHeader = step === steps.SEARCH ? "Image Library" : step === steps.ASSIGN ? "Assign to Converter" : "";

  return (
    <>
      {isLoading && <Loading />}
      <Dialog
        open={true}
        aria-labelledby="browse-image-library-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        fullScreen={!isDesktop}
        PaperProps={{
          sx: {
            borderColor: "primary.outlinedBorder",
            borderWidth: 2,
            borderStyle: "solid",
          },
        }}>
        <DialogTitle id="browse-image-library-dialog-title">
          <Typography variant="h5" textAlign="center" mt={1}>
            {dialogHeader}
          </Typography>
        </DialogTitle>
        <Divider sx={{ mx: 1, borderColor: "primary.outlinedBorder" }} />
        <DialogContent sx={{ pt: 1 }}>
          {step === steps.SEARCH && renderSearchStep()}
          {step === steps.ASSIGN && renderAssignStep()}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}>
          {step !== steps.SEARCH ? (
            <Button onClick={handleBack} color="primary" variant="outlined">
              Back
            </Button>
          ) : (
            <Button onClick={() => onClose()} color="primary" variant="outlined">
              Cancel
            </Button>
          )}
          <Button onClick={handleNext} color="primary" variant="contained" disabled={isNextButtonDisabled}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageLibraryDialog;
