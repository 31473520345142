import React from "react";
import { Grid, Paper, Typography, Divider } from "@mui/material";
import UserGeneralCard from "../ui-components/Cards/UserGeneralCard";
import UserAccountCard from "../ui-components/Cards/UserAccountCard";
import UserHistoryCard from "../ui-components/Cards/UserHistoryCard";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/constants";
import { useParams } from "react-router-dom";

const UserDetails = () => {
  const { id } = useParams();
  const { data: userData, isFetching: getUserFetching } = useQuery([QUERIES.Users, id], () => UserService.getUser(id));

  return (
    <>
      <Paper elevation={0} sx={{ backgroundColor: "#2D2D2D", borderRadius: 0 }}>
        <Grid container sx={{ py: 2, px: 3 }} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" mt={1}>
              {userData?.data?.result?.firstName} {userData?.data?.result?.lastName}
              {userData?.data?.result?.company && "\t/\t" + userData?.data?.result?.company}
            </Typography>
            <Divider sx={{ borderWidth: 1, mt: 1 }} />
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ mt: 1, px: 2 }} spacing={3}>
        <Grid item xs={12} lg={6} xl={4}>
          <UserGeneralCard />
          <UserAccountCard />
        </Grid>
        <Grid item xs={12} lg={6} xl={8}>
          <UserHistoryCard />
        </Grid>
      </Grid>
    </>
  );
};

export default UserDetails;
