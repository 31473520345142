import { Box, DialogContent, Typography, Dialog, DialogActions, DialogTitle, DialogContentText, Divider } from "@mui/material";
import React from "react";

const Modal = ({ title, description, open, handleModalClose, icon, buttons }) => {
  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      aria-labelledby="alter-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderColor: "primary.main",
          borderWidth: 2,
          borderStyle: "solid",
          padding: 1,
          maxWidth: 342,
        },
      }}>
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {icon}
          <Typography variant="h6" component="div" textAlign="center">
            {title}
          </Typography>
          {icon}
        </Box>
      </DialogTitle>
      <Divider color="primary" />
      <DialogContent sx={{ p: 1 }}>
        <DialogContentText id="alert-dialog-description" sx={{ my: 2 }}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          {buttons.map((button, index) => (
            <Box key={index}>{button}</Box>
          ))}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
