import React from "react";
import { Typography, Grid, Divider, Paper } from "@mui/material";
import { QUERIES } from "../../utils/constants";
import { useQuery } from "react-query";
import Loading from "../Loading";
import PriceListService from "../../services/price-list-service";
import FormattedDateTime from "../../utils/FormattedDateTime";

const ScrapHeaderCard = ({ title }) => {
  // get current metal prices
  const { data: scrapPrices, isFetching: getMetalPricesFetching } = useQuery([QUERIES.ScrapPrices], () => PriceListService.getCurrentScrapPriceList(), {
    staleTime: 1000 * 60 * 30, // 30 minutes in milliseconds
  });

  const isLoading = getMetalPricesFetching;

  return (
    <>
      {isLoading && <Loading />}
      <Paper elevation={0} sx={{ backgroundColor: "#2D2D2D", borderRadius: 0 }}>
        <Grid container sx={{ pt: 2, px: 3 }} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400, lineHeight: "266%", textTransform: "uppercase" }}>
              Call Apex for Straight Load Pricing!
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ScrapHeaderCard;
