import React, { useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

function IconWithText({ icon, text }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ mr: 1, fontSize: "18px" }}>{text}</Typography>
      {icon}
    </Box>
  );
}

const SubscribeSpeedDial = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        transform: "translateZ(0px)",
        flexGrow: 1,
        position: "fixed",
        bottom: 24,
        right: 16,
        "#SpeedDial-actions": {
          alignSelf: "flex-end",
        },
      }}>
      <SpeedDial
        ariaLabel="SpeedDial"
        icon={<IconWithText icon={<ChevronRight />} text="Subscribe to Apex" />}
        onClick={() => navigate("/subscriptions")}
        sx={{
          ".MuiSpeedDial-fab": {
            width: "100%",
            px: 2,
            borderRadius: "100px",
            ":hover": {
              backgroundColor: "primary.dark",
            },
          },
        }}></SpeedDial>
    </Box>
  );
};

export default SubscribeSpeedDial;
