import api from "../utils/axios-client";

const getPricelists = async () => api.get("v1/pricelists");

/**
 * Uploads a pricelist file to get back object.
 *
 * @param {File} formData - The price list.
 *
 * @returns
 */
const uploadPriceList = async (formData) => {
  return api.post("v1/pricelists", formData, { headers: { "Content-Type": "multipart/form-data" } });
};

/**
 * Gets the current scrap price list.
 *
 * @returns
 */
const getCurrentScrapPriceList = async () => api.get("v1/pricelists/scrap/current");

/**
 * Gets the pricing types.
 * 
 * @returns 
 */
const getPricingTypes = async () => api.get("v1/pricelists/pricing-types");

const PriceListService = {
  getPricelists,
  uploadPriceList,
  getCurrentScrapPriceList,
  getPricingTypes,
};

export default PriceListService;
