export const QUERIES = {
  Converters: "converters",
  Images: "images",
  Users: "users",
  PriceLists: "pricelists",
  MetalPrices: "metal-prices",
  ScrapPrices: "scrap-prices",
  Announcements: "announcements",
  Contact: "contact",
  Cores: "core",
  PricingTypes: "pricing-types",
  Subscription: "subscription",
};

export const IMAGES = {
  LOGO_LIGHT: require("../assets/images/logo-light.png"),
  LOGO_ICON_ONLY_LIGHT: require("../assets/images/logo-icon-only.png"),
  LOGO_VERTICAL_LIGHT: require("../assets/images/logo-vertical.png"),
  BACKGROUND_DESKTOP: require("../assets/images/backgrounds/background-desktop.png"),
  BACKGROUND_MOBILE: require("../assets/images/backgrounds/background-mobile.png"),
  BACKGROUND_CONTAINER_DESKTOP: require("../assets/images/backgrounds/background-container-desktop.png"),
  BACKGROUND_CONTAINER_MOBILE: require("../assets/images/backgrounds/background-container-mobile.png"),
  CONVERTER_IMAGE_COMING_SOON: require("../assets/images/converters/converter-image-coming-soon.png"),
};

export const CONFIG_KEYS = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  API_URL: process.env.REACT_APP_API_URL,
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  STRIPE_PRICING_ID: process.env.REACT_APP_STRIPE_PRICING_ID,
  CLARITY_ID: process.env.REACT_APP_CLARITY_ID,
};

export const PRICE_LIST_TYPES = {
  CONVERTER: 1,
  CORE: 2,
  SCRAP: 3,
};

export const LEGAL_LINKS = {
  PRIVACY_POLICY: "https://app.termly.io/document/privacy-policy/ab38097f-ef8b-493f-879d-3195e0ee8bc8",
  TERMS_AND_CONDITIONS: "https://app.termly.io/document/terms-of-service/9b98c060-9ffa-40dd-8eab-aa46aaccbe88",
};

export const MARGIN_TYPES = {
  DOLLAR: "DOLLAR",
  PERCENTAGE: "PERCENTAGE",
};

export const CATALYTIC_CONVERTER_IMAGE_TYPES = {
  MAIN: 1,
  FRONT: 2,
  BACK: 3,
  CODE: 4,
  SHIELD: 5,
  WITHOUT_SHIELD: 6,
  EXTRA: 7,
};

export const EVENT_TYPES = {
  SEARCH: 1,
  LOGIN: 2,
  LOGOUT: 3,
};
