import React, { useContext } from "react";
import { Grid, TextField, Typography, InputAdornment, Divider, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import ScrapListSpeedDial from "../ui-components/SpeedDials/ScrapListSpeedDial";
import ChevronButton from "../ui-components/Buttons/ChevronButton";
import { AuthContext } from "../contexts/AuthContext";
import AnnouncementCard from "../ui-components/Cards/AnnouncementCard";
import PriceListsCard from "../ui-components/Cards/PriceListsCard";
import MarketsCard from "../ui-components/Cards/MarketsCard";
import { clarity } from "react-microsoft-clarity";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/constants";
import UserService from "../services/user-service";

const Home = () => {
  const { currentClaims } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data: userProfile, isFetching: getMeFetching } = useQuery([QUERIES.Users, "me"], () => UserService.getMe());

  if (clarity.hasStarted() && userProfile) {
    window.clarity("identify", userProfile?.data?.result?.email, null, null, userProfile?.data?.result?.email);
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: "#2D2D2D",
            p: 3,
          }}>
          <Typography variant="h5">Converters Search</Typography>
          <Divider />
          <TextField
            onClick={() => {
              navigate("/converters");
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder="Search Converters..."
            fullWidth
            id="converterSearch"
            name="converterSearch"
          />
        </Grid>
        <Grid container sx={{ mt: 1, px: 2 }} spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <Box sx={{ mb: 2 }}>
              <MarketsCard hideButton={false} hasBorder={true} />
            </Box>
            <ChevronButton title="Search Cores" to={"/cores"} />
          </Grid>
          {currentClaims?.IsAdmin && (
            <>
              <Grid item xs={12} md={6} xl={4}>
                <AnnouncementCard />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <PriceListsCard />
                <ChevronButton title="APEX USERS" to={"/admin/users"} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {currentClaims?.HasPricingAccess && <ScrapListSpeedDial />}
    </>
  );
};

export default Home; // Export Home as the default component
