import React, { useEffect, useState, useMemo } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Chip, Typography, IconButton, Divider } from "@mui/material";
import { useQuery } from "react-query";
import { QUERIES, PRICE_LIST_TYPES } from "../utils/constants";
import Loading from "../ui-components/Loading";
import PriceListService from "../services/price-list-service";
import { format } from "date-fns";
import { useFormik } from "formik";
import { NumericFormat } from "react-number-format";
import { startOfDay, endOfDay, isWithinInterval } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DataTable from "react-data-table-component";
import ViewButton from "../ui-components/Buttons/ViewButton";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FormattedDateTime from "../utils/FormattedDateTime";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
};

const PriceLists = () => {
  const { data: priceLists, isFetching } = useQuery([QUERIES.PriceLists], () => PriceListService.getPricelists());
  const [filteredPriceLists, setFilteredPriceLists] = useState([]);
  const navigate = useNavigate();

  const searchFormik = useFormik({
    initialValues: {
      searchQuery: "",
      startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // week ago
      endDate: new Date(), // today
      type: "all",
    },
    onSubmit: (values) => {
      // searchUsers(values.searchQuery);
    },
  });

  useEffect(() => {
    const priceListsFilteredByDateAndType = priceLists?.data?.result?.filter((priceList) => {
      // Filter by type
      if (searchFormik.values.type !== "all" && priceList?.type?.id !== parseInt(searchFormik.values.type)) {
        return false;
      }

      // Filter by date
      const createdDate = new Date(priceList.created);
      const start = searchFormik.values.startDate ? startOfDay(new Date(searchFormik.values.startDate)) : null;
      const end = searchFormik.values.endDate ? endOfDay(new Date(searchFormik.values.endDate)) : null;

      if (!start && !end) {
        const oneWeekAgo = startOfDay(new Date());
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return createdDate >= oneWeekAgo;
      }

      if (start && !end) {
        return createdDate >= start;
      }

      if (!start && end) {
        return createdDate <= end;
      }

      if (start && end) {
        return isWithinInterval(createdDate, { start: start, end: end });
      }
    });

    setFilteredPriceLists(priceListsFilteredByDateAndType);
  }, [priceLists, searchFormik.values]);

  const columns = useMemo(
    () => [
      {
        name: "DATE UPLOADED",
        selector: (row) => format(new Date(row.created), "MM/dd/yy hh:mm a"),
        cell: (row) => {
          return (
            <Box>
              <FormattedDateTime utcDateString={row.created} formatString={"MM/dd/yy"} />
              <br />
              <FormattedDateTime utcDateString={row.created} formatString={"hh:mm a"} />
            </Box>
          );
        },
        sortable: true,
      },
      {
        name: "PRICE LIST NAME",
        selector: (row) => row.name,
        cell: (row) => <Box sx={{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.name}</Box>,
        sortable: true,
      },
      {
        name: "TYPE",
        selector: (row) => row.type.name,
        sortable: true,
      },
      {
        name: "PRICING",
        selector: (row) => row?.pricingType?.name,
        sortable: true,
      },
      {
        name: "AMOUNT",
        selector: (row) => row.count,
        cell: (row) => <NumericFormat value={row.count} displayType={"text"} thousandSeparator={true} fixedDecimalScale decimalScale={0} suffix=" units" />,
        sortable: true,
      },
      {
        name: "AVERAGE",
        selector: (row) => row.average,
        cell: (row) => <NumericFormat value={row.average} displayType={"text"} thousandSeparator={true} prefix="$" fixedDecimalScale decimalScale={2} />,
        sortable: true,
      },
      {
        name: "MARKETS",
        cell: (row) => (
          <>
            <Box>
              <NumericFormat value={row.platinumPrice} displayType={"text"} thousandSeparator={true} prefix="PT: $" fixedDecimalScale decimalScale={0} />
            </Box>
            <Box>
              <NumericFormat value={row.palladiumPrice} displayType={"text"} thousandSeparator={true} prefix="PD: $" fixedDecimalScale decimalScale={0} />
            </Box>
            <Box>
              <NumericFormat value={row.rhodiumPrice} displayType={"text"} thousandSeparator={true} prefix="RH: $" fixedDecimalScale decimalScale={0} />
            </Box>
          </>
        ),
        sortable: false,
      },
      {
        name: "CLIENTS",
        cell: (row) => (row.users.length > 0 ? row.users.map((client, index) => <Chip key={index} label={`${client.firstName} ${client.lastName}`} />) : "-"),
        sortable: true,
      },
      {
        cell: (row) => <ViewButton to={row.fileUrl} title={"VIEW"} />,
        ignoreRowClick: true,
      },
    ],
    []
  );

  function PriceListTable({ priceLists }) {
    return <DataTable columns={columns} data={priceLists} theme="dark" customStyles={customStyles} />;
  }

  return (
    <>
      {isFetching && <Loading />}
      <Grid container sx={{ p: 2, backgroundColor: "#2D2D2D" }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackOutlined />
            </IconButton>
            <Typography variant="h5">Price Lists</Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={searchFormik.handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <FormControl fullWidth sx={{ m: 1, width: 220 }}>
                  <InputLabel id="price-list-type-select-filled-label">Price List Type</InputLabel>
                  <Select labelId="price-list-type-select-label" id="price-list-type-select" label="Price List Type" {...searchFormik.getFieldProps("type")} onChange={searchFormik.handleChange}>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value={PRICE_LIST_TYPES.CONVERTER}>Converters</MenuItem>
                    <MenuItem value={PRICE_LIST_TYPES.CORE}>Cores</MenuItem>
                    <MenuItem value={PRICE_LIST_TYPES.SCRAP}>Scrap</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex" }}>
                <DatePicker sx={{ m: 1, width: 220 }} label="Start Date" value={searchFormik.values.startDate} onChange={(value) => searchFormik.setFieldValue("startDate", value)} renderInput={(params) => <TextField {...params} />} />
                <DatePicker sx={{ m: 1, width: 220 }} label="End Date" value={searchFormik.values.endDate} onChange={(value) => searchFormik.setFieldValue("endDate", value)} renderInput={(params) => <TextField {...params} />} />
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: "#2D2D2D" }}>
        <DataTable columns={columns} data={filteredPriceLists} theme="dark" customStyles={customStyles} />
      </Grid>
    </>
  );
};

export default PriceLists;
