import React from "react";
import { Card, CardContent } from "@mui/material";

const HomeCard = (props) => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        backgroundColor: "#2d2d2d",
        borderColor: "primary.outlinedBorder",
        borderWidth: 1,
        mb: 2,
        borderRadius: 1,
      }}>
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default HomeCard;
