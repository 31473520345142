import React, { useState } from "react";
import { Grid, Typography, TextField, Avatar, CardHeader, Divider, Switch, FormGroup, FormControlLabel, FormLabel, FormControl, RadioGroup, Radio, IconButton, Button, Select, MenuItem, InputLabel, CardActions } from "@mui/material";
import UserService from "../../services/user-service";
import PriceListService from "../../services/price-list-service";
import { useMutation, QueryClient, useQuery } from "react-query";
import HomeCard from "../../ui-components/Cards/HomeCard";
import { Edit, ManageAccounts } from "@mui/icons-material";
import { useFormik } from "formik";
import Loading from "../Loading";
import { useParams } from "react-router-dom";
import { PRICE_LIST_TYPES, QUERIES } from "../../utils/constants";
import { enqueueSnackbar } from "notistack";

const UserAccountCard = () => {
  const [canEdit, setCanEdit] = useState(false);
  const { id } = useParams();
  const queryClient = new QueryClient();

  // Fetch user & price list data
  const { data: userData, isFetching: getUserFetching } = useQuery([QUERIES.Users, id], () => UserService.getUser(id));
  const { data: priceListData, isFetching: getPriceListsFetching } = useQuery([QUERIES.PriceLists], () => PriceListService.getPricelists());

  // store user & price list details
  const userDetails = userData?.data?.result;
  const priceLists = priceListData?.data?.result;
  const userId = userDetails?.id;

  // disable user mutation
  const disableUserMutation = useMutation({
    mutationFn: (id) => UserService.disableUser(id),
  });

  // enable user mutation
  const enableUserMutation = useMutation({
    mutationFn: (id) => UserService.enableUser(id),
  });

  // update user permissions mutation
  const updateUserPermissionsMutation = useMutation({
    mutationFn: (data) => UserService.updateUserPermissions(userId, data),
  });

  // update override converter price list mutation
  const updateOverrideConverterPriceListMutation = useMutation({
    mutationFn: (data) => UserService.updateOverrideConverterPriceList(userId, data),
  });

  // formik for permission form
  const permissionFormik = useFormik({
    initialValues: {
      isDisabled: userDetails?.disabled ?? false,
      isAdmin: userDetails?.isAdmin ?? false,
      hasPricingAccess: userDetails?.hasPricingAccess ?? false,
      pricingType: userDetails?.pricingType ?? "Custom",
      overrideConverterPriceList: userDetails?.overrideConverterPriceList,
    },
    onSubmit: async (values) => {
      setCanEdit(false);

      // set an array of promises
      const promiseArray = [];

      // check if user is disabled
      if (values.isDisabled !== userDetails.disabled) {
        if (values.isDisabled) {
          promiseArray.push(disableUserMutation.mutateAsync(userId));
        } else {
          promiseArray.push(enableUserMutation.mutateAsync(userId));
        }
      }

      // check if user is admin or has pricing access
      if (values.isAdmin !== userDetails.isAdmin || values.hasPricingAccess !== userDetails.hasPricingAccess) {
        promiseArray.push(
          updateUserPermissionsMutation.mutateAsync({
            isAdmin: values.isAdmin,
            hasPricingAccess: values.hasPricingAccess,
          })
        );
      }

      // check if user has custom pricing
      if (values.overrideConverterPriceList?.id != userDetails.overrideConverterPriceList?.id) {
        promiseArray.push(
          updateOverrideConverterPriceListMutation.mutateAsync({
            priceListId: values.overrideConverterPriceList?.id || null,
          })
        );
      }

      // get all promise results
      var allPromiseResults = await Promise.all(promiseArray);

      // if promises are all true, open success snack
      if (allPromiseResults.every((result) => result)) {
        openSnackSuccess();
        queryClient.invalidateQueries([QUERIES.User, userId]);
      }
    },
    enableReinitialize: true,
  });

  // snack success message
  const openSnackSuccess = () => {
    enqueueSnackbar("User Account updated", { variant: "success" });
  };

  // loading
  const isLoading = getUserFetching || disableUserMutation.isLoading || enableUserMutation.isLoading || updateUserPermissionsMutation.isLoading || updateOverrideConverterPriceListMutation.isLoading || getPriceListsFetching;

  return (
    <>
      {isLoading && <Loading />}
      <HomeCard>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#F5F5F5", height: 32, width: 32 }}>
              <ManageAccounts />
            </Avatar>
          }
          action={
            <IconButton onClick={() => setCanEdit(!canEdit)}>
              <Edit />
            </IconButton>
          }
          title={<Typography variant="h5">USER ACCOUNT</Typography>}
        />
        <Divider />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel control={<Switch disabled={!canEdit} checked={permissionFormik?.values?.isDisabled ?? false} onChange={permissionFormik.handleChange} name="isDisabled" />} label={"FREEZE ACCOUNT"} />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControl disabled={!canEdit}>
              <FormLabel id="user-admin-radio-buttons-group-label">USER ADMIN?</FormLabel>
              <RadioGroup aria-labelledby="user-admin-radio-buttons-group-label" name="isAdmin" value={permissionFormik?.values?.isAdmin ?? false} onChange={permissionFormik.handleChange}>
                <FormControlLabel value={true} control={<Radio />} label="Admin" />
                <FormControlLabel value={false} control={<Radio />} label="Non-Admin" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl disabled={!canEdit}>
              <FormLabel id="user-see-prices-radio-buttons-group-label">SEE PRICES?</FormLabel>
              <RadioGroup aria-labelledby="user-see-prices-radio-buttons-group-label" name="hasPricingAccess" value={permissionFormik?.values?.hasPricingAccess ?? false} onChange={permissionFormik.handleChange}>
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" disabled={!canEdit}>
              <InputLabel id="pricing-type-select-label">Pricing Type</InputLabel>
              <Select labelId="pricing-type-select-label" id="pricing-type-select" label="Pricing Type" value={permissionFormik?.values?.pricingType ?? "Custom"} onChange={permissionFormik.handleChange} name="pricingType">
                <MenuItem value="Custom">Custom</MenuItem>
                <MenuItem value="Retail">Retail</MenuItem>
                <MenuItem value="Dealer">Dealer</MenuItem>
                <MenuItem value="Bulk">Bulk</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth disabled={!canEdit}>
              <InputLabel shrink>Price List</InputLabel>
              <Select
                value={permissionFormik.values.overrideConverterPriceList?.id ?? "-1"}
                onChange={(event) => {
                  var priceListId = event.target.value;

                  var priceList = priceLists?.find((priceList) => priceList.id === priceListId) ?? null;

                  permissionFormik.setFieldValue("overrideConverterPriceList", priceList);
                }}
                name="overrideConverterPriceList">
                <MenuItem value="-1">-</MenuItem>
                {priceLists &&
                  priceLists?.length > 0 &&
                  priceLists
                    ?.filter((priceList) => priceList?.type?.id === PRICE_LIST_TYPES.CONVERTER)
                    ?.map((priceList) => (
                      <MenuItem key={priceList.id} value={priceList.id}>
                        {priceList.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {canEdit && (
          <CardActions sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setCanEdit(false);
                permissionFormik.resetForm();
              }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={permissionFormik.handleSubmit}>
              Save
            </Button>
          </CardActions>
        )}
      </HomeCard>
    </>
  );
};

export default UserAccountCard;
