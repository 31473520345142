import React from "react";
import { Typography, Card, CardContent } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { TrendingDown, TrendingUp } from "@mui/icons-material";

const MetalsCard = ({ metal }) => {
  var isPositive = metal.changePercent > 0;
  var borderColor = isPositive ? "success.light" : "error.light";

  return (
    <Card variant="outlined" sx={{ borderColor: borderColor }}>
      <CardContent
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#404042",
          px: 1,
        }}>
        <Typography variant="h6" pt={1}>
          {metal.symbol}
        </Typography>
        <Typography variant="overline">{metal.name}</Typography>
        <Typography variant="body1">
          <NumericFormat value={metal.bidPrice} displayType={"text"} thousandSeparator={true} prefix={"$"} fixedDecimalScale decimalScale={2} />
        </Typography>
        <Typography variant="body1">
          <NumericFormat value={metal.changePercent} displayType={"text"} thousandSeparator={true} suffix="%" fixedDecimalScale decimalScale={2} />
        </Typography>
        {isPositive ? <TrendingUp sx={{ color: borderColor, height: 32, width: 32 }} /> : <TrendingDown sx={{ color: borderColor, height: 32, width: 32 }} />}
      </CardContent>
    </Card>
  );
};

export default MetalsCard;
